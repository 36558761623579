import React, { useState } from 'react'
import { Row, Col, Avatar } from 'antd'
import { Button, TextField } from '../../../components'
import { useForm, useController, Controller } from 'react-hook-form'
import { IoIosArrowForward, IoIosSearch } from 'react-icons/io'
import { HiOutlineBellAlert } from 'react-icons/hi2'
import DropDown from '../../../components/DropDown'
import { UserOutlined } from '@ant-design/icons'
import { Colors } from '../../../config'
import { Link, useNavigate } from 'react-router-dom'
import Calender from '../../../components/Calender'
import BasicText from '../../../components/Text/BasicText'
import BasicDrawer from '../../../components/Drawer'
import LabelWithTimePicker from '../../../components/LabelWithTimePicker'
import LabelWithDate from '../../../components/LabelWithDate'
import Textarea from '../../../components/TextField/TextArea'
import { date } from 'yup'
import moment from 'moment'

const MyAvailability = () => {

  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [state, setState] = useState({
    dateCell: [],
    day: [],
    year: '',
    month: '',
    time: '',
    note: [],
  })


  const { handleSubmit, control, reset } = useForm({})

  const options = [
    {
      label: 'My Availability',
      value: '1'
    },
    {
      label: 'Edit Profile',
      value: '2'
    },
    {
      label: 'Change Password',
      value: '3'
    },
  ]

  const dropDownSelector = (e) => {
    if (e.key === "tmp-0") {
      navigate('/MyAvailability')
      return
    }
    else if (e.key === "tmp-1") {
      navigate('/EditProfile')
      return
    }
    else if (e.key === 'tmp-2') {
      navigate('/ChangePassword')
      return
    }
  }

  // for cell rendering
  console.log(state?.day)
  console.log(state?.note)

  const DatecellRender = (current) => {
    const matchingIndex = state.day.indexOf(current.date());
    if (matchingIndex !== -1 && current.$M == state.month && current.$y == state.year) {
      return (
        <li className='p-2 text-sm' style={{ background: Colors.Yellow, color: Colors.White }}>{state.note[matchingIndex]}</li>
      )
    }
    else {
      return
    }
  }

  const FormHandler = (data) => {
    console.log(data)

    for (const key in data) {
      if (data[key] && data[key].$isDayjsObject) {
        const formatted = data[key].format('YYYY-MM-DD')

        const day = data[key].$D;
        const month = data[key].$M;
        const year = data[key].$y;
        data[key] = formatted;

        setState((prev) => ({
          ...prev,
          dateCell: [...prev.dateCell, formatted],
          day: [...prev.day, day],
          year: year,
          month: month
        }));
      }

      if (key == 'note') {
        setState((prev) => ({ ...prev, note: [...prev.note, data[key]] }))
      }
    }

    setDrawerOpen(false)

  }
  console.log(state)

  const DrawerCloseHandler = () => {
    setDrawerOpen(false);
    reset()
  }

  return (
    <>
      <Row className="w-full flex items-center flex-wrap mb-8">
        <Col lg={6} md={4} style={{}} className="my-2">
          <h1 className='text-xl font-semibold'>My Availability</h1>
        </Col>

        <Col lg={18} md={18} className="flex justify-end items-center py-3 px-2 gap-4 flex-wrap" style={{}}>
          <TextField.Basic placeholder={'Search here'} suffix={<IoIosSearch />} style={{ background: '#EFF2F5', border: '1px solid red' }} className='w-8/12 py-3' />
          <div className="py-3 px-3 rounded-md" style={{
            background: Colors.White,
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
          }}>
            <Link to={'/alert'}>
              <HiOutlineBellAlert size={20} />
            </Link>
          </div>

          <div className="py-2 px-4 rounded-md" style={{
            background: Colors.White,
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
          }}>

            <DropDown placement={'bottom'} onClick={(e) => dropDownSelector(e)} items={options} children={
              <div className="flex items-center gap-2 justify-between">
                <div>
                  <Avatar icon={<UserOutlined />} />
                </div>
                <div className="flex">
                  <span>Mark Smith <IoIosArrowForward className="inline" /></span>
                </div>
              </div>
            } />
          </div>
        </Col>
      </Row>

      <Row>
        <Row className='w-full py-6 mb-5 px-3 flex items-center justify-between rounded-md' style={{ background: Colors.White }}>
          <BasicText text={'My Availaiblity for 20 Jan 2024'} className='text-base font-semibold' />
          <Button.Basic onClick={() => setDrawerOpen(true)} style={{ width: "100%", height: "40px", color: Colors.White, background: Colors.Yellow }} className={'h-11 outline-none border-0 rounded-sm px-4'} text={'+ Add Preference'} />
        </Row>
        <Calender headerRender={() => { '' }} cellRender={DatecellRender} />
      </Row>

      <BasicDrawer title={'Add Preference'} openDrawer={drawerOpen} width={'600'} onClose={DrawerCloseHandler}
        children={
          <>
            <form className='w-full' onSubmit={handleSubmit(FormHandler)}>
              <Row className='w-full flex items-center justify-between'>
                <Col lg={11} >
                  <Controller
                    name='date'
                    control={control}
                    render={({ field }) => {
                      return <LabelWithDate inputClass='py-2' labeltext={'Start Date'} field={field} disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))} />
                    }}
                  />
                </Col>

                <Col lg={11}>
                  <Controller
                    name='time'
                    control={control}
                    render={({ field }) => {
                      return <LabelWithTimePicker inputClass='py-2' labeltext={'Time'} field={field} />

                    }}
                  />
                </Col>

                <Col lg={24}>
                  <Controller
                    name='note'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return <Textarea className={'w-full'} labeltext={'Notes'} rows={3} value={value} onChange={onChange} />
                    }}
                  />
                </Col>

                <Row className='w-full flex justify-end'>
                  <input type='submit' style={{ background: Colors.Yellow, color: Colors.White }} className={'cursor-pointer my-4 h-10 rounded-sm px-12'} value={'Save'} />
                </Row>
              </Row>

            </form>
          </>
        }
      />
    </>
  )
}

export default MyAvailability