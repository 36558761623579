import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Text, TextField } from '../../../components';
import { Avatar, Col, Radio, Row } from 'antd';
import { IoArrowBack } from 'react-icons/io5';
import { ApiCaller, Colors, Utils } from '../../../config';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ShiftTorAction } from '../../../store/actions';
import Circular from '../../../components/Loader/Circular';
import moment from 'moment';
import { calculateHours } from '../../../config/util/HoursCalculator';
import DateTimeComponent from '../../../config/util/DateAndTimeParser';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import socketIO from 'socket.io-client';

const TimeOfRequestDetail = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const lastMessageRef = useRef(null);

    useEffect(() => {
        dispatch(ShiftTorAction.GetTorDetail(Number(id)));
    }, [])

    const { detailLoader, torDetailData } = useSelector(state => state?.ShiftTorReducer);
    const { user } = useSelector(state => state?.AppReducer);

    const isRole = user?.data?.userRole[0];
    let currentUserId = user?.data?.id;
    let channelId = torDetailData?.chatChannel?.id;

    const [status, setStatus] = useState('');
    const [radioValue, setRadioValue] = useState(1);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (torDetailData && torDetailData?.status == 'pending') {
            let tempMsgArray = [];
            torDetailData?.chatChannel?.messages?.forEach((msg) => {
                tempMsgArray.push({
                    messageId: msg.id,
                    message: msg.message,
                    messageDateTime: msg.created_at,
                    messageSenderId: msg.sender?.id,
                    messageSenderUserName: msg.sender?.userName,
                    messageSenderFname: msg.sender?.firstName,
                    messageSenderLname: msg.sender?.lastName,
                    messageSenderImg: msg.sender?.profileImg && msg.sender?.profileImg[0]?.url
                });
            });
            setMessages(tempMsgArray)
        }
    }, [torDetailData])


    useEffect(() => {
        let socket = socketIO.connect(`${process.env.REACT_APP_SOCKET_HOST}:${process.env.REACT_APP_SOCKET_PORT}`);
        if (channelId) {
            socket.emit('joinRoom', { room: 'chatChannel.' + channelId })
            socket.on('chatMessages', (data) => {
                setMessages(prevMessages => [
                    ...prevMessages,
                    {
                        messageId: data.id,
                        message: data.message,
                        messageDateTime: data.created_at,
                        messageSenderId: data.sender?.id,
                        messageSenderUserName: data.sender?.userName,
                        messageSenderFname: data.sender?.firstName,
                        messageSenderLname: data.sender?.lastName,
                        messageSenderImg: data.sender?.profileImg && data.sender?.profileImg[0]?.url
                    }
                ]);
            })
            // Cleanup the socket listeners when the component unmounts
            return () => {
                socket.off('joinRoom');
                socket.off('chatMessages');
                socket.disconnect();
            };
        }
    }, [channelId])


    useEffect(() => {
        // scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const requestMutation = useMutation(async (data) => {
        let res = await ApiCaller.Patch(`/time-off/${Number(id)}/action`, data);
        return res;
    },
        {
            onSuccess: (res) => {
                Utils.showMessage('success', res?.data?.message)
                navigate('/workforce-management/time-of-request');
            },
            onError: (error) => {
                Utils.showMessage('error', error?.response?.data?.message);
            }
        }
    )

    const requestHandler = (status) => {
        setStatus(status)
        if (status == 'accepted') {
            let data = {
                status,
                paid: radioValue == 1 ? true : false
            }
            requestMutation.mutate(data)
        } else {
            let data = {
                status
            }
            requestMutation.mutate(data)
        }
    }

    const radioValueChangeHandler = (event) => {
        console.log(event.target.value)
        setRadioValue(event.target.value)
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value)
    }

    const btnHandler = async () => {
        if (message.length == 0) {
            return
        }
        else {
            setMessage('')
            let payload = {
                message: message
            }
            let res = await ApiCaller.Post(`/chat-channel/${Number(channelId)}/message`, payload);
        }
    }

    return (
        <>
            <Row className='mb-7'>
                <h2 className={'text-xl font-bold'}>Workforce Management</h2>
            </Row>
            {
                detailLoader ?
                    <Circular
                        spinning={detailLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />
                    :
                    <Row justify={'space-between'} align={'start'} className='p-1 w-full mb-2'>
                        <Col xl={isRole !== 'supervisor' && torDetailData?.status == 'pending' ? 15 : 24} lg={isRole !== 'supervisor' && torDetailData?.status == 'pending' ? 15 : 24} md={isRole !== 'supervisor' && torDetailData?.status == 'pending' ? 13 : 24} sm={24} xs={24} className='mb-6'>
                            <Row style={{ background: 'white' }} className='rounded-lg py-3 px-2 mb-6'>
                                <div className='w-full flex items-center justify-between px-3 flex-wrap'>
                                    <h3 className='text-lg font-semibold'>Details of TOR</h3>
                                    <span>
                                        <IoArrowBack className='cursor-pointer inline ' size={18} onClick={() => navigate('/workforce-management/time-of-request')} />
                                        <span className='ml-2'>Back</span>
                                    </span>
                                </div>
                                <div className='w-full flex items-center justify-between p-3 m-3 flex-wrap' style={{ border: '1px solid #F9F9FA' }} >
                                    <Text.Basic text={`Request for Personal ${(torDetailData?.status !== 'pending' && torDetailData?.paid == true) ? '(Paid)' : torDetailData?.status == 'pending' ? '' : '(Un-Paid)'}`} fontSize='14' font-weight='500' textAlign='start' />
                                    <span style={{ color: 'green' }} className='font-semibold'> {torDetailData?.status} <Text.Basic text={`${torDetailData?.user?.firstName + ' ' + torDetailData?.user?.lastName}`} fontSize='14' font-weight='500' color='#4E5D78' /></span>
                                </div>
                                <div className='w-full flex items-start justify-between p-3 m-3 flex-wrap' style={{ border: '1px solid #F9F9FA' }} >
                                    <div className='flex items-center gap-2 flex-wrap '>
                                        {
                                            torDetailData?.user?.profileImg ?
                                                <Avatar src={torDetailData?.user?.profileImg[0]?.url} size={35} />
                                                :
                                                <Avatar icon={<UserOutlined />} size={35} />
                                        }
                                        <div className='flex flex-col items-start justify-center flex-wrap'>
                                            <Text.Basic text={`${torDetailData?.user?.firstName + ' ' + torDetailData?.user?.lastName} requested time off`} fontSize='14' font-weight='600' textAlign='start' />
                                            <Text.Basic text={`${torDetailData?.startDate} - ${torDetailData?.endDate}`} fontSize='12' font-weight='400' textAlign='start' />
                                        </div>
                                    </div>
                                    <span style={{ color: 'green' }} className='font-semibold'>{torDetailData?.appliedHours ? (torDetailData?.appliedHours.toFixed(2)) : 0} paid hours</span>
                                </div>
                                {
                                    isRole == 'supervisor' || torDetailData?.status == 'accepted' || torDetailData?.status == 'rejected' ? '' :
                                        <>
                                            <div className='flex justify-between items-center w-full'>
                                                <div className='flex'>
                                                    <Button.Basic loader={status == 'accepted' ? requestMutation?.isLoading : false} size={'large'} text={'Accept'} onClick={() => requestHandler('accepted')} buttonStyle={{ backgroundColor: '#17BE4C', color: 'white' }} className={'rounded-md m-3'} disabled={status == 'rejected' ? true : false} />
                                                    <Button.Basic loader={status == 'rejected' ? requestMutation?.isLoading : false} size={'large'} text={'Cancel'} onClick={() => requestHandler('rejected')} buttonStyle={{ backgroundColor: '#E71D36', color: 'white' }} className={'rounded-md m-3'} disabled={status == 'accepted' ? true : false} />
                                                </div>
                                                <div>
                                                    <Radio.Group onChange={radioValueChangeHandler} value={radioValue}>
                                                        <Radio value={1}>Paid</Radio>
                                                        <Radio value={2}>Un-paid</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </>
                                }
                            </Row>
                            <Row style={{ background: 'white' }} className='rounded-lg py-3 px-2'>
                                <div className='w-full px-3'>
                                    <h3 className='text-lg font-semibold'>Paid Time Off Taken {moment().year()}</h3>
                                </div>
                                <div className='w-full flex items-center justify-between p-3 m-2 flex-wrap' style={{ border: '1px solid #F9F9FA' }} >
                                    <Text.Basic text={'Personal'} fontSize='14' font-weight='500' textAlign='start' />
                                    <Text.Basic text={`${calculateHours(torDetailData?.user?.paidHours?.totalPersonal, torDetailData?.user?.paidHours?.remainingPersonal)}` + ' Hrs'} fontSize='14' font-weight='500' color='#4E5D78' />
                                </div>
                                <div className='w-full flex items-center justify-between p-3 m-2 flex-wrap' style={{ border: '1px solid #F9F9FA' }} >
                                    <Text.Basic text={'Sick'} fontSize='14' font-weight='500' textAlign='start' />
                                    <Text.Basic text={`${calculateHours(torDetailData?.user?.paidHours?.totalSick, torDetailData?.user?.paidHours?.remainingSick)}` + ' Hrs'} fontSize='14' font-weight='500' color='#4E5D78' />
                                </div>
                                <div className='w-full flex items-center justify-between p-3 m-2 flex-wrap' style={{ border: '1px solid #F9F9FA' }} >
                                    <Text.Basic text={'Vacation'} fontSize='14' font-weight='500' textAlign='start' />
                                    <Text.Basic text={`${calculateHours(torDetailData?.user?.paidHours?.totalVacation, torDetailData?.user?.paidHours?.remainingVacation)}` + ' Hrs'} fontSize='14' font-weight='500' color='#4E5D78' />
                                </div>
                            </Row>
                        </Col>

                        {
                            torDetailData?.status == 'pending' && isRole !== 'supervisor' ?
                                <Col xl={8} lg={8} md={10} sm={24} xs={24} style={{ background: 'white' }} className='rounded-lg py-3 px-2 mb-6'>
                                    <Row className='px-3 mb-4 w-full h-5/6 flex flex-col justify-start py-2 flex-nowrap' style={{ border: '1px solid #F9F9FA', height: '430px', overflowY: 'auto' }}>
                                        <Row className='mb-4'>
                                            <h3 className='text-lg font-semibold'>Conversation / Activity</h3>
                                        </Row>

                                        <Row className='w-full mb-4' justify={'start'} align={'start'}>
                                            <div style={{ border: '1px solid #F9F9FA' }} className='p-2 w-full'>
                                                <Text.Basic text={'Request Created'} fontSize='14' font-weight='600' textAlign='start' />
                                                <Text.Label text={`By ${torDetailData?.user?.firstName + ' ' + torDetailData?.user?.lastName} on ${DateTimeComponent(torDetailData?.requestedOn, 'date')}  -  ${DateTimeComponent(torDetailData?.requestedOn, 'time')}`} className='break-all text-start' style={{ fontSize: '12px' }} />
                                            </div>
                                        </Row>
                                        {
                                            messages?.length > 0 &&
                                            messages?.map((msg) => {
                                                return (
                                                    <Row className='flex gap-2 w-full break-all mb-3 flex-nowrap items-center justify-center' justify={'start'} align={'start'} key={msg.messageId}>
                                                        <div className={`flex ${msg.messageSenderId == currentUserId ? 'items-end order-1' : ''}`}>
                                                            {
                                                                msg.messageSenderImg ? <Avatar src={msg.messageSenderImg} /> : <Avatar icon={<UserOutlined />} />
                                                            }
                                                        </div>
                                                        <div className={`flex flex-col justify-center ${msg.messageSenderId == currentUserId ? 'items-end' : 'items-start'} w-10/12`}>
                                                            <Text.Basic text={msg.message} fontSize='12' font-weight='400' color='white' textAlign='start' className='py-1 px-2 rounded-md tracking-wide' style={{ background: msg.messageSenderId == currentUserId ? '#D3D3D3' : Colors.Yellow }} />
                                                            <span style={{ fontSize: '11px', color: '#4E5D78' }}>{dayjs(msg.messageDateTime).format('YYYY-MM-DD - HH:mm')}</span>
                                                        </div>
                                                    </Row>
                                                )
                                            })
                                        }
                                        <div ref={lastMessageRef} />
                                    </Row>

                                    <Row className='mt-3 py-2 flex items-center w-full mx-auto flex-nowrap'>
                                        <input placeholder='Enter Message here..' value={message} onChange={handleMessageChange} type='text' style={{ background: '#F9F9FA', padding: '10px' }} className='w-4/5 outline-none' />
                                        <button style={{ color: 'white', background: Colors.Yellow, padding: '10px' }} onClick={btnHandler} className='rounded-e w-5/12 sm:w-1/5 md:w-fit xl:w-1/5 px-3'>Submit</button>
                                    </Row>

                                </Col>
                                : ""

                        }


                    </Row>

            }

        </>
    )
}

export default TimeOfRequestDetail