import React, { useEffect, useState } from 'react'
import BasicDrawer from '../../../components/Drawer'
import { useForm, Controller } from 'react-hook-form'
import { Col, ConfigProvider, Row, TimePicker } from 'antd'
import LabelWithSelect from '../../../components/LabelWithSelect'
import { ApiCaller, Colors, Utils } from '../../../config'
import LabelWithTimePicker from '../../../components/LabelWithTimePicker'
import LabelWithField from '../../../components/LabelWithField'
import { Button, TextField } from '../../../components'
import RangeDatePicker from '../../../components/Picker/RangePicker'
import dayjs from 'dayjs'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { getAllUsers } from '../../../config/getApis'
import { yupResolver } from '@hookform/resolvers/yup'
import { TorRequestSchema } from '../../../config/Schema'
import { useDispatch, useSelector } from 'react-redux'
import { ShiftTorAction, UserManagementAction } from '../../../store/actions'
import BasicText from '../../../components/Text/BasicText'

const RequestTimeOffDrawer = ({ drawerStates, setDrawerStates, timeOffRequest, setTimeOffRequest }) => {

    const [state, setState] = useState({
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: '',
        paidHoursLeft: 0
    })

    // validations only for time and date, rest will be handle by the useForm and yup.
    const [validation, setValidations] = useState({
        startTime: '',
        endTime: '',
        startDate: '',
    })

    const usersOptions = useQuery('users', getAllUsers, { enabled: drawerStates?.requestTimeOffDrawerOpen });

    const userDetailData = useSelector(state => state?.UserManagementReducer?.UserDetailData);

    const dispatch = useDispatch();

    const defaultValues = {
        userId: '',
        type: '',
        notes: ''
    }
    const schema = TorRequestSchema;

    const { handleSubmit, control, reset, setValue, watch } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    })

    const watchFields = watch(["userId", "type"]);
    let [userId, type] = watchFields;

    let user = userId;
    let typeOffRequst = type;

    useEffect(() => {
        if (user && typeOffRequst) {
            let payload = {
                id: Number(user),
            }
            dispatch(UserManagementAction.GetUserDetail(payload))
            user = ''
            typeOffRequst = ''
        }
    }, [user, typeOffRequst])

    useEffect(() => {
        if (userDetailData?.paidHours) {
            let paidHoursLeft = null;
            switch (type) {
                case 'personal':
                    paidHoursLeft = userDetailData?.paidHours?.remainingPersonal;
                    break;
                case 'sick':
                    paidHoursLeft = userDetailData?.paidHours?.remainingSick;
                    break;
                case 'vacation':
                    paidHoursLeft = userDetailData?.paidHours?.remainingVacation;
                    break;
                default:
                    paidHoursLeft = null;
            }
            setState(prevState => ({ ...prevState, paidHoursLeft }));
        }
    }, [userDetailData])

    const closeHandler = () => {
        setDrawerStates({ ...drawerStates, requestTimeOffDrawerOpen: false })
        reset(defaultValues)
        setState({
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
            paidHoursLeft: 0
        })
    }

    const TorRequestMutation = useMutation(async (data) => {
        let res = await ApiCaller.Post('/time-off', data);
        return res;
    },
        {
            onSuccess: (res) => {
                const { page, limit, search, filterDrawer } = timeOffRequest;
                const { status, timeOffType, dateStr } = filterDrawer;
                setDrawerStates({ ...drawerStates, requestTimeOffDrawerOpen: false });
                Utils.showMessage('success', res?.data?.message)
                dispatch(ShiftTorAction.GetTors({ page: page, limit: limit, search: search, filterDrawer: { status: status, timeOffType: timeOffType, dateStr: dateStr } }))
                setTimeOffRequest({ ...timeOffRequest, page: page, limit: limit, search: search, filterDrawer: { status: status, timeOffType: timeOffType, dateStr: dateStr } })
                reset(defaultValues)
                setState({
                    startTime: '',
                    endTime: '',
                    startDate: '',
                    endDate: '',
                    paidHoursLeft: 0
                })
            },
            onError: (error) => {
                Utils.showMessage('error', error?.response?.data?.message);
            }
        }
    )

    const submitHandler = (data) => {
        if (!state?.startTime || !state?.endTime || !state?.startDate || !state?.endDate) {
            setValidations({
                startTime: !state?.startTime ? 'Start time is required' : '',
                endTime: !state?.endTime ? 'End time is required' : '',
                startDate: !state?.startDate ? 'Date is required' : '',
            })
            return
        }
        else if (state?.startTime && state?.endTime && state?.startDate && state?.endDate) {
            setValidations(
                {
                    startTime: '',
                    endTime: '',
                    startDate: '',
                }
            )
            if (dayjs(state.startTime, 'HH:mm').format('HH:mm') == dayjs(state.endTime, 'HH:mm').format('HH:mm')) {
                console.log(dayjs(state.startTime, 'HH:mm').format('HH:mm'), dayjs(state.endTime, 'HH:mm').format('HH:mm'))
                Utils.showMessage('error', 'Start and End Time cannot be same')
                return
            }
            TorRequestMutation.mutate(data)
        }
    }


    return (
        <>
            <BasicDrawer
                openDrawer={drawerStates.requestTimeOffDrawerOpen}
                onClose={closeHandler}
                title={'Request Time Off'}
                width={500}
                children={
                    <>
                        <form>
                            <Row className='w-full flex items-start mb-4' gutter={[4]}>
                                <Col lg={13} md={13} sm={24} xs={24}>
                                    <Controller
                                        name='userId'
                                        defaultValue=''
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithSelect options={usersOptions?.data ? usersOptions?.data : []} field={field} labeltext={'WHO'} inputClass={'w-full py-1 outline-none rounded h-9'} labelClass='font-normal' style={{ color: Colors.LabelColor }} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </>
                                        )

                                        }
                                    />
                                </Col>
                                <Col lg={11} md={11} sm={24} xs={24}>
                                    <Controller
                                        name='type'
                                        defaultValue=''
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithSelect
                                                    options={
                                                        [
                                                            {
                                                                label: 'personal',
                                                                value: 'personal'
                                                            },
                                                            {
                                                                label: 'sick',
                                                                value: 'sick'
                                                            },
                                                            {
                                                                label: 'vacation',
                                                                value: 'vacation'
                                                            }
                                                        ]}
                                                    field={field}
                                                    labeltext={'Time Off Type'} inputClass={'w-full h-9 outline-none rounded'} labelClass='font-normal' style={{ color: Colors.LabelColor }} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </>
                                        )

                                        }
                                    />
                                </Col>

                            </Row>

                            <Row className='w-full flex items-center justify-start mb-5' gutter={[4]}>
                                <Col lg={13} md={13} sm={24} xs={24} className='self-end mb-4 md:mb-0'>
                                    <Controller
                                        name='startTime'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <>
                                                <div className='flex flex-col'>
                                                    <label style={{ color: Colors.LabelColor }} className='font-normal'>Start Time</label>
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                DatePicker: {
                                                                    optionSelectedBg: Colors.Yellow,
                                                                    optionActiveBg: Colors.LightGray,
                                                                    optionSelectedColor: Colors.White
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <TimePicker
                                                            allowClear={false}
                                                            value={state?.startTime ? state?.startTime : dayjs()}
                                                            use12Hours
                                                            format="h:mm A"
                                                            onChange={(time) => {
                                                                setState((prev) => ({ ...prev, startTime: time }));
                                                                setValue('startTime', time?.format('HH:mm'));
                                                            }}
                                                            className='outline-none py-1 rounded-md h-9'
                                                        />
                                                    </ConfigProvider>
                                                    {validation?.startTime && <p style={{ color: Colors.Error }}>{validation?.startTime}</p>}
                                                </div>
                                            </>
                                        )}

                                    />
                                </Col>

                                <Col lg={11} md={11} sm={24} xs={24}>
                                    <Controller
                                        name='endTime'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <>
                                                <div className='flex flex-col'>
                                                    <label style={{ color: Colors.LabelColor }} className='font-normal'>End Time</label>
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                DatePicker: {
                                                                    optionSelectedBg: Colors.Yellow,
                                                                    optionActiveBg: Colors.LightGray,
                                                                    optionSelectedColor: Colors.White
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <TimePicker
                                                            allowClear={false}
                                                            value={state?.endTime ? state?.endTime : dayjs()}
                                                            use12Hours
                                                            format="h:mm A"
                                                            onChange={(time) => {
                                                                setState((prev) => ({ ...prev, endTime: time }));
                                                                setValue('endTime', time?.format('HH:mm'));

                                                            }}
                                                            className='outline-none py-1 rounded-md h-9'
                                                        />
                                                    </ConfigProvider>
                                                    {validation?.endTime && <p style={{ color: Colors.Error }}>{validation?.endTime}</p>}
                                                </div>
                                            </>
                                        )}

                                    />
                                </Col>

                            </Row>
                            <Row className='w-full flex items-center justify-start mb-5' gutter={[4]}>
                                <Col lg={13} md={13} sm={24} xs={24} className='mb-4 md:mb-0'>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Date Range</label>
                                    <div>
                                        <Controller
                                            name='startDate'
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState }) => (
                                                <>
                                                    <RangeDatePicker
                                                        allowClear={false}
                                                        format={'DD-MM-YYYY'}
                                                        className={'outline-none py-1 rounded-md h-9 w-full'}
                                                        defaultValue={state?.startDate ? [dayjs(state?.startDate), dayjs(state?.endDate)] : [dayjs(), dayjs()]}
                                                        onChange={(date, dateStrings) => { setState((prev) => ({ ...prev, startDate: date && date[0], endDate: date && date[1] })); setValue('startDate', dateStrings && dateStrings[0]); setValue('endDate', dateStrings && dateStrings[1]) }}
                                                        disabledDate={(current) => current?.isBefore(moment().subtract(1, "day"))}
                                                    />
                                                    {validation?.startDate && <p style={{ color: Colors.Error }}>{validation?.startDate}</p>}
                                                </>
                                            )}
                                        />

                                    </div>
                                </Col>

                                <Col lg={11} md={11} sm={24} xs={24} className=''>
                                    <label>Paid Hours Left</label>
                                    <BasicText text={state?.paidHoursLeft} className='px-2 rounded-md h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                            </Row>

                            <Row gutter={[4]} className='w-full flex items-center justify-start mb-4'>
                                <Col span={24}>
                                    <Controller
                                        name='notes'
                                        control={control}
                                        defaultValue=''
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <div className=''>
                                                <TextField.Textarea
                                                    style={{ color: Colors.LightGray }}
                                                    placeholder={'Message'}
                                                    value={value}
                                                    onChange={onChange}
                                                    labeltext={'Note'}
                                                    className={'outline-none h-10 rounded'}
                                                />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[10]} className='mt-6 px-2'>
                                <Col span={24} className='flex justify-end'>
                                    <Button.Basic loader={TorRequestMutation?.isLoading} onClick={handleSubmit(submitHandler)} size={'large'} text={'Send Request'} className={'rounded-md h-11 px-6'} style={{ backgroundColor: Colors.Yellow, color: 'white' }} />
                                </Col>
                            </Row>
                        </form>

                    </>
                }
            />
        </>
    )
}

export default RequestTimeOffDrawer