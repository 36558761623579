import { Avatar, Col, ConfigProvider, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import BasicText from '../../components/Text/BasicText'
import { Colors } from '../../config'
import { UserOutlined } from '@ant-design/icons'
import Label from '../../components/Text/label'
import filter from '../../assets/images/filter.png'
import tab from '../../assets/images/tab.png'
import Text from '../../components/Text'
import { FaLocationDot } from "react-icons/fa6";
import eligiblity from '../../assets/images/eligibility.png'
import schedule from '../../assets/images/schedule.png'
import Map from '../../assets/images/Map.png'
import { FaUserEdit } from "react-icons/fa";
import './AttendenceManagement.css'
import { useRef } from 'react';
import { convertToPdf } from '../../config/util/pdfConverter'
import BasicDrawer from '../../components/Drawer'
import { Button } from '../../components'
import RangeDatePicker from '../../components/Picker/RangePicker'
import dayjs from 'dayjs'
import EditTimeDrawer from './EditTimeDrawer'
import DateTimeComponent from '../../config/util/DateAndTimeParser'
import convertMinutesToHoursAndMinutes from '../../config/util/MinutesToHoursAndMinutes'
import { useDispatch, useSelector } from 'react-redux'
import { AttendenceManagementAction } from '../../store/actions'
import Circular from '../../components/Loader/Circular'
import { useLocation } from 'react-router-dom'


const AttendenceManagement = () => {

    // used to get and send a reference to convertToPdf function that will generate a pdf.
    const contentRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();

    const { propertiesData, schedulesData, usersData, loader, attendenceData } = useSelector(state => state?.AttendenceManagementReducer);

    const [attendenceCalculation, setAttendenceCalculation] = useState({
        overTime: 0, totalHours: 0, totalWorked: 0, Differences: 0
    })

    useEffect(() => {
        if(location.pathname !== '/attendence-management'){
            dispatch(AttendenceManagementAction.ClearAttendence())
        }
    },[location.pathname])

    useEffect(() => {
        if (attendenceData.length > 0) {

            let attendenceCalc = { overTime: 0, totalHours: 0, totalWorked: 0, Differences: 0 };

            for (let attendence of attendenceData) {
                attendenceCalc['overTime'] += attendence['differenceMinutes'];
                attendenceCalc['totalHours'] += attendence['requiredMinutes'];
                attendenceCalc['totalWorked'] += attendence['workedMinutes'];
                attendenceCalc['Differences'] += attendence['differenceMinutes']
            }

            if (Math.sign(attendenceCalc['overTime']) == 1) {
                return setAttendenceCalculation(attendenceCalc)
            } else {
                attendenceCalc['overTime'] = 0;
                return setAttendenceCalculation(attendenceCalc)
            }

        }
        else {
            setAttendenceCalculation({
                overTime: 0, totalHours: 0, totalWorked: 0, Differences: 0
            })
        }

    }, [attendenceData])


    const [drawerStates, setDrawerStates] = useState({
        filter: false,
        checkoutTimeDrawer: false
    })

    const [state, setState] = useState({
        filterDrawer: {
            property: '',
            schedule: '',
            user: '',
            startingDate: '',
            endingDate: ''
        }
    })

    const [editTimeData, setEditTimeData] = useState({
        id: '',
        checkIn: '',
        checkOut: ''
    })

    const editTimeHandler = (id, checkIn, checkOut) => {
        setEditTimeData({ id, checkIn, checkOut })
        setDrawerStates({ ...drawerStates, checkoutTimeDrawer: true })
    }

    // Filter states cleaner
    const clearFilters = () => {
        if (drawerStates.filter) {
            setState((prev) => ({
                ...prev,
                filterDrawer: {
                    property: '',
                    schedule: '',
                    user: '',
                    startingDate: '',
                    endingDate: ''
                }
            }))
        }
        return
    }

    const attendenceFilters = () => {
        if (state?.filterDrawer?.startingDate == '' || state?.filterDrawer?.endingDate == '' || state?.filterDrawer?.property == '' || state?.filterDrawer?.user == '' || state?.filterDrawer?.schedule == '') {
            return
        }
        else {
            dispatch(AttendenceManagementAction.GetAttendence({ state }));
            setDrawerStates({ ...drawerStates, filter: false })
        }
    }

    return (
        <>
            <Row align={"middle"} justify={"start"} className='mt-2 mb-6'>
                <BasicText text={'Attendence Management'} className='text-xl font-semibold' />
            </Row>

            <Row className='w-full px-3 rounded-lg py-2' style={{ background: 'white' }} >
                <Col span={24} className='flex items-center justify-between flex-wrap'>
                    <div className='p-2 flex sm:items-center items-start flex-wrap gap-2 flex-col sm:flex-row'>
                        <div className='flex items-center gap-2 flex-wrap'>
                            {
                                attendenceData[0]?.user?.profileImg ? <Avatar src={attendenceData[0]?.user?.profileImg[0].url} /> : <Avatar icon={<UserOutlined />} />
                            }
                            <BasicText text={attendenceData?.length > 0 ? attendenceData[0]?.user?.firstName + ' ' + attendenceData[0]?.user?.lastName : '-'} style={{ fontSize: '0.9rem' }} className='font-medium mt-1' />
                        </div>
                        <div className='flex items-center flex-wrap gap-1'>
                            {/* <div className='flex items-end gap-1'>
                                <Text.Label text={'Regular'} style={{ fontSize: '0.8rem' }} className={'font-medium'} />
                                <span style={{ fontSize: '0.8rem' }} className='font-medium'>40</span>
                                <Text.Label text={'|'} style={{ fontSize: '0.8rem' }} />
                            </div> */}
                            <div className='flex items-end gap-1'>
                                <Text.Label text={'Overtime'} style={{ fontSize: '0.8rem' }} className={'font-medium'} />
                                <span style={{ fontSize: '0.8rem' }} className='font-medium'>{attendenceCalculation?.overTime ? attendenceCalculation?.overTime : 0}</span>
                                <Text.Label text={'|'} style={{ fontSize: '0.8rem' }} />
                            </div>
                            <div className='flex items-end gap-1'>
                                <Text.Label text={'Paid Total'} style={{ fontSize: '0.8rem' }} className={'font-medium'} />
                                <span style={{ fontSize: '0.8rem' }} className='font-medium'>{attendenceCalculation.totalWorked ? convertMinutesToHoursAndMinutes(attendenceCalculation.totalWorked) : 0}</span>
                                {/* <Text.Label text={'|'} style={{ fontSize: '0.8rem' }} /> */}
                            </div>
                            {/* <div className='flex items-end gap-1'>
                                <Text.Label text={'Scheduled/Worked'} style={{ fontSize: '0.8rem' }} className={'font-medium'} />
                                <span style={{ fontSize: '0.8rem' }} className='font-medium'>0</span>
                            </div> */}
                        </div>
                    </div>
                    <div className='p-2 flex items-center gap-2'>
                        <img src={tab} onClick={() => attendenceData.length > 0 ? convertToPdf(contentRef) : ''} className='cursor-pointer' />
                        <img src={filter} onClick={() => setDrawerStates({ ...drawerStates, filter: true })} style={{ height: '30px' }} width={35} className='cursor-pointer' />
                    </div>
                </Col>
                {
                    loader ?
                        <Circular
                            spinning={loader}
                            size={40}
                            color={Colors.Yellow}
                            containerStyle={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        />
                        :
                        attendenceData.length <= 0 ?
                            <Row className='mx-1 w-full p-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' }}>
                                <p>Kindly select a user through filter</p>
                            </Row>
                            :
                            <>
                                {/* // Attendence table */}
                                <Row ref={contentRef}>
                                    <Row className='w-full mx-1 rounded-t-md mt-4 hidden md:flex md:items-center' style={{ background: "#EFF2F5" }}>
                                        <Col xl={3} lg={3} md={3} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Day</Col>
                                        <Col xl={2} lg={2} md={2} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Time in</Col>
                                        <Col xl={2} lg={2} md={2} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Time out</Col>
                                        <Col xl={2} lg={2} md={2} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Total hours</Col>
                                        <Col xl={7} lg={4} md={4} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Details</Col>
                                        <Col xl={2} lg={2} md={3} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Worked</Col>
                                        <Col xl={2} lg={4} md={3} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Scheduled</Col>
                                        <Col xl={2} lg={3} md={3} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center', borderRight: '1px solid rgb(203 213 225)' }} className='py-2'>Difference</Col>
                                        <Col xl={2} lg={2} md={2} sm={0} xs={0} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-2'>Edit</Col>
                                    </Row>

                                    {
                                        attendenceData && attendenceData?.map((attendenceRecord,index) => {
                                            return (
                                                <Row key={index} className='w-full mx-1 md:flex md:items-center border-table mb-2 md:mb-0' style={{ borderBottom: '1px solid rgb(203 213 225)' }}>
                                                    <Col xl={3} lg={3} md={3} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-left-custom border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Day</h3>
                                                        <BasicText text={attendenceRecord?.shift?.shiftDate} style={{ fontSize: '12px' }} className='font-medium break-all' />
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Time in</h3>
                                                        <BasicText text={attendenceRecord?.checkIn ? DateTimeComponent(attendenceRecord?.checkIn, 'time') : '-'} style={{ fontSize: '12px' }} className='font-medium break-all' />
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Time out</h3>
                                                        <BasicText text={attendenceRecord?.checkOut ? DateTimeComponent(attendenceRecord?.checkOut, 'time') : '-'} style={{ fontSize: '12px' }} className='font-medium break-all' />
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Total hours</h3>
                                                        <BasicText text={attendenceRecord?.requiredMinutes ? convertMinutesToHoursAndMinutes(attendenceRecord?.requiredMinutes) : '-'} style={{ fontSize: '12px' }} className='font-medium break-all' />
                                                    </Col>
                                                    <Col xl={7} lg={4} md={4} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-1 flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Details</h3>
                                                        <div className='flex items-center gap-1 md:gap-2 flex-wrap md:px-1 px-0 md:h-full'>
                                                            <div className='flex items-start md:items-center gap-2 px-4 py-2 w-fit' style={{ background: Colors.Yellow, color: 'white' }}>
                                                                <img src={schedule} style={{ height: '15px', width: '15px' }} className='hidden md:inline-block' />
                                                                <span style={{ fontSize: '12px' }}>{attendenceRecord?.shift?.property ? attendenceRecord?.shift?.property?.schedules[0]?.name : '-'}</span>
                                                            </div>
                                                            <div className='flex items-start md:items-center gap-2 px-4 py-2 w-fit' style={{ background: Colors.Yellow, color: 'white' }}>
                                                                <img src={eligiblity} style={{ height: '15px', width: '15px' }} className='hidden md:inline-block' />
                                                                <span style={{ fontSize: '12px' }}>{attendenceRecord?.shift?.eligibilitity ? attendenceRecord?.shift?.eligibilitity?.name : '-'}</span>
                                                            </div>
                                                            <div className='flex items-start md:items-center justify-start gap-1 px-4 py-2 w-fit' style={{ background: Colors.Yellow, color: 'white' }}>
                                                                <img src={Map} style={{ height: '15px', width: '12px' }} className='hidden md:inline-block' />
                                                                <span className='break-all text-start' style={{ fontSize: '12px' }}>{attendenceRecord?.shift?.address ? `${attendenceRecord?.shift?.address?.streetNumber},${attendenceRecord?.shift?.address?.streetName}-${attendenceRecord?.shift?.address?.city}` : '-'}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={2} lg={2} md={3} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Worked</h3>
                                                        <BasicText text={attendenceRecord?.workedMinutes ? convertMinutesToHoursAndMinutes(attendenceRecord?.workedMinutes) : '-'} style={{ fontSize: '12px' }} className='font-medium break-all' />
                                                    </Col>
                                                    <Col xl={2} lg={4} md={3} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Scheduled</h3>
                                                        <BasicText text={'-'} style={{ fontSize: '12px' }} className='font-medium break-all' />
                                                    </Col>
                                                    <Col xl={2} lg={3} md={3} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Difference</h3>
                                                        <BasicText text={attendenceRecord?.differenceMinutes ? convertMinutesToHoursAndMinutes(attendenceRecord?.differenceMinutes) : '-'} style={{ fontSize: '12px' }} className='font-medium break-all' />
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={24} xs={24} style={{ color: '#4E5D78', textAlign: 'center' }} className='py-1 md:py-2 px-2 md:px-0 md:h-full flex items-start justify-start flex-col md:items-center md:justify-center md:flex-row border-right-custom'>
                                                        <h3 className='md:hidden font-semibold' style={{ fontSize: '16px' }}>Edit</h3>
                                                        <FaUserEdit size={20} color={Colors.Yellow} className='cursor-pointer' onClick={() => editTimeHandler(attendenceRecord?.shift_user_id, attendenceRecord?.checkIn, attendenceRecord?.checkOut)} />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }


                                </Row>

                                {/* total */}
                                <Col span={24} className='flex items-center flex-wrap gap-3 my-2'>
                                    <div className='flex items-end gap-1'>
                                        <Text.Label text={'Total Hours: '} style={{ fontSize: '1rem' }} className={'font-medium'} />
                                        <span style={{ fontSize: '1rem' }} className='font-bold'>{attendenceCalculation.totalHours ? convertMinutesToHoursAndMinutes(attendenceCalculation.totalHours) : 0}</span>
                                    </div>
                                    <div className='flex items-end gap-1'>
                                        <Text.Label text={'Total Worked: '} style={{ fontSize: '1rem' }} className={'font-medium'} />
                                        <span style={{ fontSize: '1rem' }} className='font-bold'>{attendenceCalculation.totalWorked ? convertMinutesToHoursAndMinutes(attendenceCalculation.totalWorked) : 0}</span>
                                    </div>
                                    <div className='flex items-end gap-1'>
                                        <Text.Label text={'Differences: '} style={{ fontSize: '1rem' }} className={'font-medium'} />
                                        <span style={{ fontSize: '1rem' }} className='font-bold'>{attendenceCalculation.Differences ? convertMinutesToHoursAndMinutes(attendenceCalculation.Differences) : 0}</span>
                                    </div>
                                </Col>
                            </>
                }

            </Row>


            <BasicDrawer
                openDrawer={drawerStates?.filter}
                onClose={() => setDrawerStates({ ...drawerStates, filter: false })}
                width={300}
                title={'Filter'}
                children={
                    <>
                        <Row gutter={[16, 32]} className='flex w-full flex-col h-full justify-between'>
                            <Row gutter={[16, 32]}>

                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Pay Period</label>
                                    <RangeDatePicker
                                        className={'h-9'}
                                        allowClear={true}
                                        format={'YYYY-MM-DD'}
                                        defaultValue={state?.filterDrawer?.startingDate ? [dayjs(state?.filterDrawer?.startingDate), dayjs(state?.filterDrawer?.endingDate)] : [dayjs(), dayjs()]}
                                        onChange={(dates, dateStrings) => {
                                            if (dateStrings[0]) {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        user: '',
                                                        schedule: '',
                                                        property: '',
                                                        startingDate: dateStrings[0],
                                                        endingDate: dateStrings[1]
                                                    }
                                                }));
                                                dispatch(AttendenceManagementAction.GetAttendenceProperties({ startDate: dateStrings[0], endDate: dateStrings[1] }))
                                            } else {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        user: '',
                                                        schedule: '',
                                                        property: '',
                                                        startingDate: '',
                                                        endingDate: ''
                                                    }
                                                }));
                                                dispatch(AttendenceManagementAction.ClearAttendenceFilter())
                                            }
                                        }}
                                    // disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                    />
                                </Col>

                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Property</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            value={state?.filterDrawer?.property}
                                            onChange={(value) => {
                                                if (value !== undefined && value !== null) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            property: value
                                                        }
                                                    }));
                                                    dispatch(AttendenceManagementAction.GetAttendenceSchedules(value))

                                                } else {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            property: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                            options={propertiesData ? propertiesData : []}
                                        />
                                    </ConfigProvider>
                                </Col>

                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Schedule</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            value={state?.filterDrawer?.schedule}
                                            onChange={(value) => {
                                                if (value !== undefined && value !== null) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            schedule: value
                                                        }
                                                    }));
                                                    dispatch(AttendenceManagementAction.GetAttendenceUsers({ state, value }))
                                                } else {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            schedule: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                            options={schedulesData ? schedulesData : []}
                                        />
                                    </ConfigProvider>
                                </Col>

                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>User</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            value={state?.filterDrawer?.user}
                                            onChange={(value) => {
                                                if (value !== undefined && value !== null) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            user: value
                                                        }
                                                    }));
                                                } else {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            user: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                            options={usersData ? usersData : []}
                                        />
                                    </ConfigProvider>
                                </Col>

                            </Row>

                            <Row className='w-full flex justify-between gap-4 px-2 self-end' align={'middle'}>
                                <Col span={11}>
                                    <Button.Basic disabled={state?.filterDrawer?.startingDate == '' || state?.filterDrawer?.endingDate == '' || state?.filterDrawer?.property == '' || state?.filterDrawer?.user == '' || state?.filterDrawer?.schedule == '' ? true : false} onClick={attendenceFilters} style={{ color: 'white', backgroundColor: Colors.Yellow }} text={'Search'} className={'w-full rounded'} size={'large'} />
                                </Col>
                                <Col span={11}>
                                    <Button.Basic onClick={clearFilters} style={{ color: 'white', backgroundColor: Colors.Error }} text={'Clear'} className={'w-full rounded'} size={'large'} />
                                </Col>
                            </Row>
                        </Row>
                    </>
                }
            />

            <EditTimeDrawer
                drawerStates={drawerStates}
                setDrawerStates={setDrawerStates}
                editTimeData={editTimeData}
                filterStates={state}
                setFilterStates={setState}
            />

        </>
    )
}

export default AttendenceManagement