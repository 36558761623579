import React, { useState, useEffect } from 'react'
import BasicDrawer from '../../../components/Drawer';
import LabelWithSelect from '../../../components/LabelWithSelect';
import { Col, ConfigProvider, Row, Select, TimePicker } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import BasicText from '../../../components/Text/BasicText';
import { ApiCaller, Colors, Utils } from '../../../config';
import { Button } from '../../../components';
import { OpenShiftDetail } from '../../../config/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { ShiftSchedulerAction } from '../../../store/actions';

const DetailShiftDrawer = ({ drawerStates, setDrawerStates, openShiftDetailData, state, setState }) => {

    const schema = OpenShiftDetail;
    const dispatch = useDispatch()

    const [shiftAppliedUsers, setShiftAppliedUsers] = useState([]);
    console.log("openShiftDetailData", openShiftDetailData)

    let userArray = [];
    let appliedUserArray = openShiftDetailData?.shiftUser?.map((item) => {
        if (item?.status == 'applied') {
            return (
                userArray.push({
                    label: `${item?.user?.firstName} ${item?.user?.lastName}`,
                    value: item?.user?.id
                })
            )
        }
    })

    const { handleSubmit, control, reset } = useForm(
        {
            mode: 'onChange',
            resolver: yupResolver(schema)
        }
    )


    const userAcceptedMutation = useMutation(async (data) => {
        let resp = await ApiCaller.Post(`/shift/${openShiftDetailData?.id}/approve`, data);
        return resp;
    },
        {
            onSuccess: (resp) => {
                const { limit, page, tabKey, filterDrawer } = state;
                Utils.showMessage('success', resp?.data?.message);
                setDrawerStates({ ...drawerStates, openShiftDetailDrawer: false })
                dispatch(ShiftSchedulerAction.GetShifts({ limit: limit, tabKey: tabKey, page: page, filterDrawer }))
                setState({ ...state, limit: limit, tabKey: tabKey, page: page, filterDrawer: { ...state.filterDrawer } })
            },
            onError: (err) => {
                Utils.showMessage('error', err?.response?.data?.message)
            }
        }

    )

    const shiftUpdateHandler = (data) => {
        userAcceptedMutation.mutate(data)
    }

    const afterDrawerClose = () => {
        if (!drawerStates?.openShiftDetailDrawer) {
            setShiftAppliedUsers([])
            reset({ usersId: [] })
        }
    }

    return (
        <div>
            <BasicDrawer
                openDrawer={drawerStates.openShiftDetailDrawer}
                width={500}
                title={'Open Shift Detail'}
                onClose={() => setDrawerStates({ ...drawerStates, openShiftDetailDrawer: false })}
                afterOpenChange={afterDrawerClose}
                children={
                    <>
                        <form>
                            <Row gutter={[16, 32]} className='mb-3'>

                                <Col lg={13} md={13} sm={24} xs={24}>
                                    <Controller
                                        name='usersId'
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <LabelWithSelect mode={'multiple'} options={userArray?.length > 0 ? userArray : []} labeltext={'Assign To'} field={field} labelClass={'font-normal'} inputClass={'outline-none py-1 rounded'} />
                                                {fieldState?.error && <p style={{ color: Colors.Error }}>{fieldState?.error?.message}</p>}
                                            </>
                                        )}
                                    />
                                </Col>
                                <Col lg={11} md={11} sm={12} xs={12} className='mt-4'>
                                    <label>Un-paid Break</label>
                                    <BasicText text={openShiftDetailData?.break && openShiftDetailData.break} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                            </Row>

                            <Row gutter={[16, 32]} className='mb-3' >

                                <Col lg={13} md={13} sm={12} xs={12}>
                                    <label>Start Time</label>
                                    <BasicText text={openShiftDetailData?.startTime && openShiftDetailData.startTime} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                                <Col lg={11} md={11} sm={12} xs={12} className=''>
                                    <label>End Time</label>
                                    <BasicText text={openShiftDetailData?.endTime && openShiftDetailData?.endTime} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                            </Row>

                            <Row gutter={[16, 32]} className='mb-3' >

                                <Col lg={13} md={13} sm={12} xs={12}>
                                    <label>Property Site</label>
                                    <BasicText text={openShiftDetailData?.property && openShiftDetailData?.property?.name} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                                <Col lg={11} md={11} sm={12} xs={12} className=''>
                                    <label>Position</label>
                                    <BasicText text={openShiftDetailData?.eligibilitity && openShiftDetailData?.eligibilitity?.name} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                            </Row>

                            <Row gutter={[16, 32]} className='mb-3' >
                                <Col lg={13} md={13} sm={12} xs={12}>
                                    <label>Start Date</label>
                                    <BasicText text={openShiftDetailData?.startDate ? openShiftDetailData?.startDate : openShiftDetailData?.shiftDate} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                                <Col lg={11} md={11} sm={12} xs={12} className=''>
                                    <label>End Date</label>
                                    <BasicText text={openShiftDetailData?.endDate ? openShiftDetailData?.endDate : openShiftDetailData?.shiftDate} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} className='mb-3' >
                                <Col lg={13} md={13} sm={12} xs={12}>
                                    <label>Repeat Shift</label>
                                    <BasicText text={openShiftDetailData?.recurringFrequency && openShiftDetailData?.recurringFrequency} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>

                                <Col lg={11} md={11} sm={12} xs={12} className=''>
                                    <label>Location</label>
                                    <BasicText text={openShiftDetailData?.location?.address[0] && `${openShiftDetailData?.location?.address[0]?.streetNumber}, ${openShiftDetailData?.location?.address[0]?.streetName}...`} className='overflow-y-auto overflow-x-hidden w-full px-2 rounded h-9 flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} className='mb-3' >
                                <Col span={24} className=''>
                                    <label>Notes</label>
                                    <textarea value={openShiftDetailData?.notes && openShiftDetailData?.notes} rows={4} disabled className='overflow-y-auto overflow-x-hidden w-full px-2 rounded  flex items-center' style={{ width: '100%', backgroundColor: '#EFF2F5', paddingTop: '5px', paddingBottom: '5px' }} />
                                </Col>
                            </Row>


                            <Row gutter={[16, 32]} className='my-6'>
                                <Col span={24} className='flex items-center justify-end'>
                                    <Button.Basic
                                        onClick={handleSubmit(shiftUpdateHandler)}
                                        htmlType="submit"
                                        loader={userAcceptedMutation.isLoading}
                                        size={'large'} text={'Update'} buttonStyle={{ backgroundColor: Colors.Yellow, color: 'white' }} className={'px-12 rounded-md h-12'} />
                                </Col>
                            </Row>
                        </form>
                    </>
                }
            />
        </div>
    )
}

export default DetailShiftDrawer