import React, { useState, useEffect } from 'react'
import FilterBar from '../../../components/FilterBar'
import { useSelector, useDispatch } from 'react-redux'
import { Col, ConfigProvider, DatePicker, Row, Spin, Select, Avatar } from 'antd'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import TableWrapper from '../../../components/Grid'
import filter from '../../../assets/images/filter.png'
import { Button } from '../../../components'
import { Colors } from '../../../config'
import BasicDrawer from '../../../components/Drawer'
import moment from 'moment'
import RequestTimeOffDrawer from './RequestTimeOffDrawer'
import { UserOutlined } from '@ant-design/icons'
import { FaEye } from "react-icons/fa";
import { ShiftTorAction } from '../../../store/actions'
import dayjs from 'dayjs'



const TimeOfRequest = () => {

    const { user } = useSelector(state => state?.AppReducer)
    const isRole = user?.data?.userRole[0];
    const { loader, torData } = useSelector(state => state?.ShiftTorReducer);
    const { data, paginate } = torData;

    const dispatch = useDispatch()

    const [state, setState] = useState({
        search: '',
        page: 1,
        limit: 10,
        filterDrawer: {
            status: '',
            timeOffType: '',
            dateStr: ''
        }
    })

    const [drawerStates, setDrawerStates] = useState({
        filter: false,
        requestTimeOffDrawerOpen: false
    })

    useEffect(() => {
        dispatch(ShiftTorAction.GetTors(state))
    }, [])

    const navigate = useNavigate()

    const timeOfRequestHandler = () => {
        setDrawerStates({ ...drawerStates, requestTimeOffDrawerOpen: true })
    }

    const debounce = (query, delay) => {
        let timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            return (
                dispatch(ShiftTorAction.GetTors({ search: query, page: 1, limit: state?.limit, filterDrawer: { timeOffType: state?.filterDrawer?.timeOffType, status: state?.filterDrawer?.status, dateStr: state?.filterDrawer?.dateStr } }))
            )
        }, [delay])
    }

    const handleInputChange = (event) => {
        const query = event?.target.value;
        setState({ ...state, page: 1, search: query });
        debounce(query, 2000)
    }

    const incidentFilters = () => {
        if (state?.filterDrawer?.timeOffType || state?.filterDrawer?.status || state?.filterDrawer?.dateStr) {
            dispatch(ShiftTorAction.GetTors({ ...state, page: 1 }))
            setState({ ...state, page: 1 })
            setDrawerStates({ ...drawerStates, filter: false })
        }
        else {
            return
        }
    }

    const clearFilters = () => {
        const { search, limit } = state;
        setState({ ...state, page: 1, filterDrawer: { timeOffType: '', status: '', dateStr: '' } })
        dispatch(ShiftTorAction.GetTors({ search: search, page: 1, limit: limit, filterDrawer: { timeOffType: '', status: '', dateStr: '' } }))
        setDrawerStates({ ...drawerStates, filter: false })
    }

    const handlePaginationChange = (page, limit) => {
        const { search, filterDrawer } = state;
        const { status, timeOffType, dateStr } = filterDrawer;
        setState((prev) => ({ ...prev, page: page, limit: limit, filterDrawer: { status: status, timeOffType: timeOffType, dateStr: dateStr } }));
        let payload = {
            search: search,
            limit: limit,
            page: page,
            filterDrawer: {
                status: status,
                timeOffType: timeOffType,
                dateStr: dateStr
            }
        }
        dispatch(ShiftTorAction.GetTors(payload))
    }

    const TorColumns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: '',
            align: "left",
            render: (text, record) => (

                <Row justify={"center"} style={{ display: 'flex', alignItems: "center" }}>
                    <Col span={24} className='flex justify-start items-center' >
                        {
                            record?.user?.profileImg ?
                                <Avatar className='mr-5' size={40} src={record?.user?.profileImg[0]?.url} />
                                :
                                <Avatar className='mr-5' size={40} icon={<UserOutlined />} />
                        }
                        <span>{record?.user?.firstName + ' ' + record?.user?.lastName}</span>
                    </Col>
                </Row >

            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: '',
            align: "left",
            render: (text, record) => (

                <Row justify={"center"}>
                    <Col span={24}>
                        <span>{record?.type}</span>
                    </Col>
                </Row >

            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: '',
            align: "left",
            render: (text, record) => (

                <Row justify={"center"}>
                    <Col span={24}>
                        <span>{record.status}</span>
                    </Col>
                </Row >

            )
        },
        {
            title: 'Details',
            dataIndex: 'startDate',
            key: '',
            align: "left",
            render: (text, record) => (

                <Row justify={"center"}>
                    <Col span={24}>
                        <span>{record?.startDate + ' - ' + record?.endDate}</span>
                    </Col>
                </Row >

            )
        },
        {
            title: 'Requested On',
            dataIndex: 'requestedOn',
            key: '',
            align: "left",
            render: (text, record) => (

                <Row justify={"center"}>
                    <Col span={24}>
                        <span>{record?.requestedOn ?  dayjs(record?.requestedOn).format('YYYY-MM-DD') : '-'}</span>
                    </Col>
                </Row >

            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: '',
            align: "left",
            render: (text, record) => (

                <Row justify={"center"} style={{ display: 'flex', alignItems: "center" }}>
                    <Col span={24}>
                        <span className='cursor-pointer' onClick={() => navigate(`/workforce-management/TOR/detail/${record?.id}`)}>{<FaEye size={20} color={Colors.Yellow} />}</span>
                    </Col>
                </Row >

            )
        },

    ]


    return (
        <>
            <FilterBar
                title='Workforce Management'
                isFilter={false}
                btnText={'Request TimeOff'}
                isRole={isRole}
                AddUserHandler={timeOfRequestHandler}
                SearchValue={state?.search}
                handleInputChange={handleInputChange}
            />

            <Row className='flex items-center gap-2 my-5'>
                <IoArrowBack className='cursor-pointer' size={18} onClick={() => navigate('/workforce-management')} />
                Back
            </Row>


            <Row gutter={[8, 32]} className='w-fit sm:w-fit lg:w-fit xl:w-full' style={{ background: "white", padding: '16px', borderRadius: "10px" }}>

                <Col lg={24}>
                    <Row className='w-full'>
                        <Col lg={24}>
                            <TableWrapper
                                tableStyle={{ borderRadius: "40px", width: '100%' }}
                                headerbg={"red"}
                                tableColumns={TorColumns}
                                title={() => (
                                    <div className='flex items-center justify-between'>
                                        <span className='text-lg font-bold'>Manage Time Off Requests</span>
                                        <img src={filter} height={35} width={35} className='cursor-pointer' onClick={() => setDrawerStates((prev) => ({ ...prev, filter: true }))} />
                                    </div>
                                )}
                                tableData={data}
                                total={paginate?.total}
                                pageSize={state.limit}
                                currentPage={state.page}
                                handlePaginationChange={handlePaginationChange}
                                loading={loader && <Spin />}
                                rowClassName={'row-antd'}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row >

            <BasicDrawer
                openDrawer={drawerStates?.filter}
                onClose={() => setDrawerStates({ ...drawerStates, filter: false })}
                width={300}
                title={'Filter'}
                children={
                    <>
                        <Row gutter={[16, 32]} className='flex h-full justify-between'>
                            <Row>
                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Status</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            options={[
                                                {
                                                    label: 'pending',
                                                    value: 'pending'
                                                },
                                                {
                                                    label: 'accepted',
                                                    value: 'accepted'
                                                },
                                                {
                                                    label: 'rejected',
                                                    value: 'rejected'
                                                }
                                            ]}
                                            value={state?.filterDrawer?.status && state?.filterDrawer?.status}
                                            onChange={(value) => {
                                                if (value !== undefined || value !== null) {
                                                    setState({ ...state, filterDrawer: { ...state?.filterDrawer, status: value } })
                                                } else {
                                                    setState({ ...state, filterDrawer: { ...state?.filterDrawer, status: "" } })
                                                }
                                            }}
                                        />
                                    </ConfigProvider>
                                </Col>

                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Type</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            options={[
                                                {
                                                    label: 'personal',
                                                    value: 'personal'
                                                },
                                                {
                                                    label: 'sick',
                                                    value: 'sick'
                                                },
                                                {
                                                    label: 'vacation',
                                                    value: 'vacation'
                                                }
                                            ]}
                                            value={state?.filterDrawer?.timeOffType && state?.filterDrawer?.timeOffType}
                                            onChange={(value) => {
                                                if (value !== undefined || value !== null) {
                                                    setState({ ...state, filterDrawer: { ...state?.filterDrawer, timeOffType: value } })
                                                } else {
                                                    setState({ ...state, filterDrawer: { ...state?.filterDrawer, timeOffType: "" } })
                                                }
                                            }}
                                        />
                                    </ConfigProvider>
                                </Col>


                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Date</label>
                                    <DatePicker
                                        className='w-full h-9'
                                        allowClear={true}
                                        format={'YYYY-MM-DD'}
                                        disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                        value={state?.filterDrawer?.dateStr && dayjs(state?.filterDrawer?.dateStr)}
                                        onChange={(date, dateString) => {
                                            if (dateString) {
                                                setState({ ...state, filterDrawer: { ...state?.filterDrawer, dateStr: dateString } })
                                            } else {
                                                setState({ ...state, filterDrawer: { ...state?.filterDrawer, dateStr: "" } })
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className='w-full flex justify-between gap-4 px-2 self-end' align={'middle'}>
                                <Col span={11}>
                                    <Button.Basic onClick={incidentFilters} style={{ color: 'white', backgroundColor: Colors.Yellow }} text={'Search'} className={'w-full rounded'} size={'large'} />
                                </Col>
                                <Col span={11}>
                                    <Button.Basic onClick={clearFilters} style={{ color: 'white', backgroundColor: Colors.Error }} text={'Clear'} className={'w-full rounded'} size={'large'} />
                                </Col>
                            </Row>
                        </Row>
                    </>
                }
            />

            <RequestTimeOffDrawer
                drawerStates={drawerStates}
                setDrawerStates={setDrawerStates}
                timeOffRequest={state}
                setTimeOffRequest={setState}
            />

        </>
    )
}

export default TimeOfRequest

const data = [
    {
        name: 'zaid',
        type: 'testing',
        status: 'testing',
        details: 'testing',
        request: 'testing'



    }
]