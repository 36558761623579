import { AppMiddleware, AttendenceManagementMiddleware, ClientManagementMiddleware, ManageIncidentsMiddleware, SettingMiddleware, ShiftSchedulerMiddleware, ShiftTorMiddleware, SiteManagementMiddleware, UserManagementMiddleware } from "../middlewares"
import { takeLatest, all } from 'redux-saga/effects'
import { LOGOUT, SIGNIN, EMAIL_VERIFICATION, OTP_VERIFICATION, PASSWORD_RESET, GET_USERS, ADD_USER, UPDATE_USER, UPDATE_USER_STATUS, GET_USER_DETAIL, GET_PROPERTY, UPDATE_PROPERTY_STATUS, ADD_PROPERTY, ADD_ADDRESS, EDIT_ADDRESS, GET_ADDRESS, EDIT_PROPERTY, GET_PROPERTIES, GET_DOCUMENT, EDIT_DOCUMENT, GET_LOCATIONS, DATA_CLEAR, GET_INCIDENT_TYPES, ADD_INCIDENT, GET_CHECKPOINTS, EDIT_CHECKPOINT, EDIT_LOCATION, GET_GEOFENCE, EDIT_GEOFENCE, GET_PROPERTY_DETAIL, ADD_DOCUMENT, ADD_LOCATION, ADD_PROPERTY_INCIDENT, ADD_CHECKPOINT, ADD_GEOFENCE, ADD_PHONE, EDIT_PHONE, ADD_ADDRESS_TYPE, GET_COMPANIES, GET_COMPANIES_DETAIL, GET_INVITED_CLIENTS, GET_SCHEDULES, GET_ELEGIBILITIES, GET_INVITED_CLIENT_DETAIL, COMPANIES_STATUS_UPDATE, GET_MANAGE_INCIDENTS, GET_MANAGE_INCIDENTS_DETAIL, GET_ASSIGN_INCIDENT_DETAIL, GET_POTENTIAL_TAKERS, GET_SHIFTS, GET_ATTENDENCE, GET_ATTENDENCE_PROPERTIES, GET_ATTENDENCE_SCHEDULES, GET_ATTENDENCE_USERS, GET_TOR_DETAIL, GET_TOR } from "../constants"

export function* Sagas() {
    yield all([
        yield takeLatest(SIGNIN, AppMiddleware.SignIn),
        yield takeLatest(LOGOUT, AppMiddleware.Logout),
        yield takeLatest(EMAIL_VERIFICATION, AppMiddleware.EmailVerify),
        yield takeLatest(OTP_VERIFICATION, AppMiddleware.OtpVerification),
        yield takeLatest(PASSWORD_RESET, AppMiddleware.ResetPassword),
        yield takeLatest(GET_USERS, UserManagementMiddleware.GetUser),
        yield takeLatest(GET_USER_DETAIL, UserManagementMiddleware.GetUserDetail),
        yield takeLatest(ADD_USER, UserManagementMiddleware.AddUser),
        yield takeLatest(UPDATE_USER, UserManagementMiddleware.UpdateUser),
        yield takeLatest(UPDATE_USER_STATUS, UserManagementMiddleware.UpdateUserStatus),


        // Site Management
        yield takeLatest(GET_PROPERTY, SiteManagementMiddleware.GetProperty),
        yield takeLatest(GET_PROPERTY_DETAIL, SiteManagementMiddleware.GetPropertyDetail),
        yield takeLatest(UPDATE_PROPERTY_STATUS, SiteManagementMiddleware.UpdatePropertyStatus),
        yield takeLatest(ADD_PROPERTY, SiteManagementMiddleware.AddProperty),
        yield takeLatest(ADD_PROPERTY_INCIDENT, SiteManagementMiddleware.AddPropertyIncident),
        yield takeLatest(GET_PROPERTIES, SiteManagementMiddleware.GetProperties),
        yield takeLatest(EDIT_PROPERTY, SiteManagementMiddleware.EditProperty),
        yield takeLatest(ADD_ADDRESS, SiteManagementMiddleware.AddAddress),
        yield takeLatest(EDIT_ADDRESS, SiteManagementMiddleware.EditAddress),
        yield takeLatest(GET_ADDRESS, SiteManagementMiddleware.GetAddress),
        yield takeLatest(GET_DOCUMENT, SiteManagementMiddleware.GetDocuments),
        yield takeLatest(EDIT_DOCUMENT, SiteManagementMiddleware.EditDcoument),
        yield takeLatest(ADD_DOCUMENT, SiteManagementMiddleware.AddDocument),
        yield takeLatest(GET_LOCATIONS, SiteManagementMiddleware.GetLocations),
        yield takeLatest(EDIT_LOCATION, SiteManagementMiddleware.EditLocation),
        yield takeLatest(ADD_LOCATION, SiteManagementMiddleware.AddLocation),
        yield takeLatest(GET_INCIDENT_TYPES, SiteManagementMiddleware.GetIncidentTypes),
        yield takeLatest(ADD_INCIDENT, SiteManagementMiddleware.AddIncident),
        yield takeLatest(ADD_CHECKPOINT, SiteManagementMiddleware.AddCheckpoint),
        yield takeLatest(GET_CHECKPOINTS, SiteManagementMiddleware.GetCheckPoints),
        yield takeLatest(EDIT_CHECKPOINT, SiteManagementMiddleware?.EditCheckpoint),
        yield takeLatest(GET_GEOFENCE, SiteManagementMiddleware.GetGeofence),
        yield takeLatest(ADD_GEOFENCE, SiteManagementMiddleware.AddGeofence),
        yield takeLatest(EDIT_GEOFENCE, SiteManagementMiddleware.EditGeofence),
        yield takeLatest(ADD_PHONE, SiteManagementMiddleware.AddPhone),
        yield takeLatest(EDIT_PHONE, SiteManagementMiddleware.EditPhone),
        yield takeLatest(ADD_ADDRESS_TYPE, SiteManagementMiddleware.GetAddressTypes),

        //Client Management
        yield takeLatest(GET_COMPANIES, ClientManagementMiddleware.GetCompanies),
        yield takeLatest(GET_COMPANIES_DETAIL, ClientManagementMiddleware.GetCompaniesDetails),
        yield takeLatest(GET_INVITED_CLIENTS,ClientManagementMiddleware.GetInvitedClient),
        yield takeLatest(GET_INVITED_CLIENT_DETAIL,ClientManagementMiddleware.GetInvitedClientDetail),
        yield takeLatest(COMPANIES_STATUS_UPDATE, ClientManagementMiddleware.CompaniesStatusUpdate),

        //Settings
        yield takeLatest(GET_SCHEDULES, SettingMiddleware.GetSchedules),
        yield takeLatest(GET_ELEGIBILITIES, SettingMiddleware.GetEligibilites),

        //ManageIncidents
        yield takeLatest(GET_MANAGE_INCIDENTS, ManageIncidentsMiddleware.GetManageIncidents),
        yield takeLatest(GET_MANAGE_INCIDENTS_DETAIL, ManageIncidentsMiddleware.GetManageIncidentsDetail),
        yield takeLatest(GET_ASSIGN_INCIDENT_DETAIL, ManageIncidentsMiddleware.GetAssignIncidentDetail),

        //Shift Scheduler
        yield takeLatest(GET_POTENTIAL_TAKERS, ShiftSchedulerMiddleware.GetPotentialTakers),
        yield takeLatest(GET_SHIFTS, ShiftSchedulerMiddleware.GetShifts),

         // Shift TOR
         yield takeLatest(GET_TOR, ShiftTorMiddleware.GetTors),
         yield takeLatest(GET_TOR_DETAIL, ShiftTorMiddleware.GetTorDetail),
 
        //Attendence Management
        yield takeLatest(GET_ATTENDENCE_PROPERTIES, AttendenceManagementMiddleware.GetAttendencePropertiesMiddleware),
        yield takeLatest(GET_ATTENDENCE_SCHEDULES, AttendenceManagementMiddleware.GetAttendenceSchedulesMiddleware),
        yield takeLatest(GET_ATTENDENCE_USERS, AttendenceManagementMiddleware.GetAttendenceUsersMiddleware),
        yield takeLatest(GET_ATTENDENCE, AttendenceManagementMiddleware.GetAttendenceMiddleware)
    ])

}