import { put } from "redux-saga/effects";
import { ApiCaller, Utils } from "../../config";
import { ShiftTorAction } from "../actions";

export default class ShiftTorMiddleware {
    static *GetTors(params) {
        const { payload } = params;
        try {
            let res = yield ApiCaller.Get(`/time-off-requests?perPage=${payload?.limit}&page=${payload?.page}&search=${payload?.search}&status=${payload?.filterDrawer?.status ? payload?.filterDrawer?.status : ''}&time_off_type=${payload?.filterDrawer?.timeOffType ? payload?.filterDrawer?.timeOffType : ''}&date=${payload?.filterDrawer?.dateStr ? payload?.filterDrawer?.dateStr : ''}`);
            if (res?.data?.status == 200) {
                yield put(ShiftTorAction.GetTorsSuccess(res?.data));
            }
        } catch (error) {
            yield put(ShiftTorAction.GetTorsFailure());
            Utils.showMessage('error', error?.response?.data?.message)
        }

    }

    static *GetTorDetail(params) {
        const { payload, cb } = params;
        console.log(payload)
        try {
            let res = yield ApiCaller.Get(`/time-off/${payload}`);
            if (res?.data?.status == 200) {
                Utils.showMessage('success', res?.data?.message)
                yield put(ShiftTorAction.GetTorDetailSuccess(res?.data?.data))
            }
        } catch (error) {
            yield put(ShiftTorAction.GetTorDetailFailure());
            Utils.showMessage('error', error?.response?.data?.message)
        }
    }
}