import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import BasicDrawer from '../../components/Drawer';
import { Col, ConfigProvider, Row, TimePicker } from 'antd';
import { ApiCaller, Colors, Utils } from '../../config';
import dayjs from 'dayjs';
import { Button } from '../../components';
import { useMutation } from 'react-query';
import DateTimeComponent from '../../config/util/DateAndTimeParser';
import { useDispatch } from 'react-redux';
import { AttendenceManagementAction } from '../../store/actions';

const EditTimeDrawer = ({ drawerStates, setDrawerStates, editTimeData, filterStates, setFilterStates }) => {
    const { control, reset, setValue } = useForm();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        startTime: null,
        endTime: null
    });

    const start = DateTimeComponent(editTimeData?.checkIn, 'time');
    const end = DateTimeComponent(editTimeData?.checkOut, 'time');

    useEffect(() => {
        if (!start || !end) return;

        const startTimeDayJs = dayjs(editTimeData.checkIn, 'YYYY-MM-DD HH:mm');
        const endTimeDayJs = dayjs(editTimeData.checkOut, 'YYYY-MM-DD HH:mm');

        reset({
            startTime: startTimeDayJs,
            endTime: endTimeDayJs
        });

        setState({
            startTime: startTimeDayJs,
            endTime: endTimeDayJs
        });
    }, [editTimeData, reset]);

    const [validation, setValidations] = useState({
        startTime: '',
        endTime: ''
    });

    const closeDrawerHandler = () => {
        reset();
        setState({
            startTime: null,
            endTime: null
        });
        setValidations({
            startTime: '',
            endTime: ''
        });
        setDrawerStates({ ...drawerStates, checkoutTimeDrawer: false });
    };

    const editTimeMutation = useMutation(async (data) => {
        const checkIn = data.startTime.format('YYYY-MM-DD HH:mm');
        const checkOut = data.endTime.format('YYYY-MM-DD HH:mm');
        let res = await ApiCaller.Patch(`/attendance`, { checkIn, checkOut, shiftUserId: editTimeData?.id });
        return res
    }, {
        onSuccess: (res) => {
            setState({
                startTime: null,
                endTime: null
            });
            setDrawerStates({ ...drawerStates, checkoutTimeDrawer: false });
            dispatch(AttendenceManagementAction.GetAttendence({filterStates}));
            setFilterStates({ ...filterStates });
            Utils.showMessage('success', res?.data?.message);
        },
        onError: (error) => {
            Utils.showMessage('error', error?.response?.data?.message)
        }
    });

    const editTimeHandler = (event) => {
        event.preventDefault()
        if (!state?.startTime && !state?.endTime) {
            setValidations({
                startTime: 'StartTime is Required',
                endTime: 'EndTime is Required'
            });
        } else if (!state?.endTime) {
            setValidations({
                startTime: '',
                endTime: 'EndTime is Required'
            });
        } else if (!state?.startTime) {
            setValidations({
                endTime: '',
                startTime: 'StartTime is Required'
            });
        } else {
            setValidations({
                startTime: '',
                endTime: ''
            });
            editTimeMutation.mutate(state);
        }
    };

    return (
        <div>
            <BasicDrawer
                openDrawer={drawerStates?.checkoutTimeDrawer}
                onClose={closeDrawerHandler}
                width={300}
                title={'Edit Time'}
                children={
                    <>
                        <form>
                            <Row gutter={[16, 32]} className='mb-5'>
                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <Controller
                                        name='startTime'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <div className='flex flex-col'>
                                                <label style={{ color: Colors.LabelColor }} className='font-normal'>Check-In Time</label>
                                                <ConfigProvider
                                                    theme={{
                                                        components: {
                                                            DatePicker: {
                                                                optionSelectedBg: Colors.Yellow,
                                                                optionActiveBg: Colors.LightGray,
                                                                optionSelectedColor: Colors.White
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <TimePicker
                                                        value={state?.startTime || dayjs()}
                                                        use12Hours
                                                        format="h:mm A"
                                                        onChange={(time) => {
                                                            const formattedTime = time.format('HH:mm');
                                                            let date = editTimeData?.checkIn && editTimeData?.checkIn?.split(" ");
                                                            let startDate = date[0] || dayjs().format('YYYY-MM-DD');
                                                            const newTime = dayjs(`${startDate} ${' '} ${formattedTime}`, 'YYYY-MM-DD HH:mm');
                                                            setState((prev) => ({ ...prev, startTime: newTime }));
                                                            setValue('startTime', formattedTime);
                                                        }}
                                                        className='outline-none py-1 rounded-md h-9'
                                                    />
                                                </ConfigProvider>
                                                {validation?.startTime && <p style={{ color: Colors.Error }}>{validation?.startTime}</p>}
                                            </div>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} className='mb-6'>
                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <Controller
                                        name='endTime'
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState }) => (
                                            <div className='flex flex-col'>
                                                <label style={{ color: Colors.LabelColor }} className='font-normal'>Check-Out Time</label>
                                                <ConfigProvider
                                                    theme={{
                                                        components: {
                                                            DatePicker: {
                                                                optionSelectedBg: Colors.Yellow,
                                                                optionActiveBg: Colors.LightGray,
                                                                optionSelectedColor: Colors.White
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <TimePicker
                                                        value={state?.endTime || dayjs()}
                                                        use12Hours
                                                        format="h:mm A"
                                                        onChange={(time) => {
                                                            const formattedTime = time.format('HH:mm');
                                                            let date = editTimeData?.checkOut && editTimeData?.checkOut?.split(" ");
                                                            let startDate = date[0] || dayjs().format('YYYY-MM-DD');
                                                            const newTime = dayjs(`${startDate} ${' '} ${formattedTime}`, 'YYYY-MM-DD HH:mm');
                                                            setState((prev) => ({ ...prev, endTime: newTime }));
                                                            setValue('endTime', formattedTime);
                                                        }}
                                                        className='outline-none py-1 rounded-md h-9'
                                                    />
                                                </ConfigProvider>
                                                {validation?.endTime && <p style={{ color: Colors.Error }}>{validation?.endTime}</p>}
                                            </div>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row className='w-full my-6'>
                                <Col lg={24}>
                                    <Button.Basic loader={editTimeMutation.isLoading} type={'submit'} onClick={editTimeHandler} size={'large'} className={'rounded-md px-3 w-full'} style={{ backgroundColor: Colors.Yellow, color: 'white' }} text={'Save Edit Time'} />
                                </Col>
                            </Row>
                        </form>
                    </>
                }
            />
        </div>
    );
};

export default EditTimeDrawer;
