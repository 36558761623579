
import * as yup from 'yup';

// User Auth
export const UserSignin = yup.object().shape({
    email: yup.string()
        .email('Invaild Email')
        .required('Email is required')
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, 'Please enter a valid email'),
    password: yup.string()
        .required('Password is required')

})

export const UserEmail = yup.object().shape({
    email: yup.string().required('Email is required').email('Invaild Email')
});

export const UserPasswordReset = yup.object().shape({
    newpassword: yup.string()
        .min(12)
        .max(32)
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lower case letter')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .required('New password is required'),
    reenterpassword: yup.string().label('confirm password').required('Re-enter password is required').oneOf([yup.ref('newpassword'), null], 'Passwords do not match'),

});

// dashboard password change validation
export const UserPasswordChange = yup.object().shape({
    oldPassword: yup.string().required('Password is required'),

    newPassword: yup.string()
        .min(12)
        .max(32)
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lower case letter')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .required('Password is required'),

    confirmPassword: yup.string().label('confirm password').required().oneOf([yup.ref('newPassword'), null], 'Passwords do not match'),
})

// dashboard userdetail validation
export const UserDetail = yup.object().shape({
    firstName: yup.string().min(2, 'First Name must contain at least two characters'),
    lastName: yup.string().min(2, 'Last Name must contain at least two characters'),
    email: yup.string()
        .email('Invaild Email')
        .required('Email is required')
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, 'Please enter a valid email'),
    phone: yup.number().required('Phone number is required').min(11).typeError('phone number should be a number')
})

//(1 KB = 1024 bytes)
export const AddUserSchema = yup.object().shape({
    firstName: yup.string().min(2, 'First Name must contain at least two characters'),
    lastName: yup.string().min(2, 'Last Name must contain at least two characters'),
    phoneNo: yup.string()
        .required('Phone number is required')
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        ),
    email: yup.string().email('Invalid email').required('email is required')
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, 'Please enter a valid email'),
    userName: yup.string()
        .required('Username is required')
        .max(15, 'Username max 15 characters long')
        .matches(/^[a-z0-9]+$/, 'Username must contain only lowercase letters or numbers'),
    eligibilityIds: yup.array().required('eligibilty is required').min(1, "Please select atleast one eligibilty"),
    scheduleIds: yup.array().required('schedule is required').min(1, "Please select atleast one schedule"),
    roleId: yup.string().required('role is required'),
    'unarmedExpiration': yup.date().required(),
    'armedExpiration': yup.date().notRequired(),
    // ojt: yup.date().required(),
    annualOne: yup.date().notRequired(),
    annualTwo: yup.date().notRequired()
})


export const AddProperty = yup.object().shape({
    name: yup.string().required('Property Name is required').min(2,'Name must be at least 2 characters'),
    code: yup.string().required('Property Code is required'),
    companyId: yup.string().required('Client is required'),
    scheduleId: yup.string().required('Schedule is required'),
})

export const AddAddress = yup.object().shape({
    streetType: yup.string().required('Street type is required'),
    addressType: yup.string().required('Address type is required'),
    streetNumber: yup.string().required('Street Number is required'),
    streetName: yup.string().required('Street Name is required').min(3).max(30),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required').matches(/^[a-zA-Z]+$/, 'Must be only alphabets')
        .min(2, 'Must be exactly 2 characters')
        .max(2, 'Must be exactly 2 characters'),
    zip: yup.string().required('Zip Code is required')
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, 'Must be exactly 5 digits')
        .max(5, 'Must be exactly 5 digits'),
    buildingNumber: yup.string().notRequired(),
    longitude: yup.number().required('Longitude is required').typeError('Longitude must be a number'),
    latitude: yup.number().required('Latitude is required').typeError('Latitude must be a number')
})

export const AddDocuments = yup.object().shape({
    name: yup.string().required('Name is required').min(2,'Name must be at least 2 characters'),
    accessibility: yup.string().required('Acessibility is required'),
    description: yup.string().required('Description is required').min(10)
})

export const AddLocation = yup.object().shape({
    // addressId: yup.array().required('eligibilty is required').min(1, "Please select atleast one eligibilty"),
    name: yup.string().required('Name is required').min(2,'Name must be at least 2 characters'),
    description: yup.string().required('Description is required').min(10)
})

export const AddIncident = yup.object().shape({
    name: yup.string().required('name is required').min(2,'Name must be at least 2 characters'),
    level: yup.string().required('level is required')
})

export const AddCheckPoints = yup.object().shape({
    addressId: yup.string().required('Property address is required'),
    unit: yup.string().required('CheckPoint unit is required'),
    propertiesIncidentTypesId: yup.string().required('CheckPoint incident is required'),
    // name: yup.string().required('Name is required').min(3).max(30),
    // location: yup.string().required('Location is required'),
    // code: yup.string().required('Code is required'),
    // reportNotes: yup.string().required('Report Notes is required').min(10),
    // officerNotes: yup.string().required('Officer Notes is required').min(10)
})


export const AddPhoneNumber = yup.object().shape({
    'Phone Number1': yup.
        string()
        .required('Phone Number is required')
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                // if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        )
    ,
    'Phone Type1': yup.string().required('Phone Type is required'),
    'Phone Number2': yup.
        string()
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        ),
    'Phone Number3': yup.
        string()
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        ),
    'Phone Number4': yup.
        string()
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        ),
    'Phone Number5': yup.
        string()
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        )
})

export const AddClient = yup.object().shape({
    name: yup.string().required('Name is required').min(2,'Name must be at least 2 characters'),
    url: yup.string()
        .notRequired()
        .test(
            'is-url',
            'Invalid URL', // Custom error message
            function (value) {
                // If the value is empty, no validation error should be thrown
                if (!value) {
                    return true;
                }
                // Regular expression to validate the URL
                const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)*$/;
                return urlRegex.test(value);
            }
        ),
    streetNumber: yup.string().required('Street Number is required'),
    streetName: yup.string().required('Street Name is required').min(3).max(30),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required').matches(/^[a-zA-Z]+$/, 'Must be only alphabets')
        .min(2, 'Must be exactly 2 characters')
        .max(2, 'Must be exactly 2 characters'),
    zip: yup.string().required('Zip Code is required')
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, 'Must be exactly 5 digits')
        .max(5, 'Must be exactly 5 digits'),
    streetType: yup.string().required('StreetType is required'),
    buildingNumber: yup.string().notRequired(),
    addressType: yup.string().required('AddressType is required'),
    phoneNo: yup.
        string().
        required('Phone Number is required')
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        ),
    fax: yup.string().notRequired(),
    note: yup.string().notRequired()

})

export const AddGeoFence = yup.object().shape({
    name: yup.string().required('GeoFence is required').min(2,'Name must be at least 2 characters'),
    latitude: yup.string().required('Latitude is required'),
    longitude: yup.string().required('Latitude is required'),

})

export const InviteClient = yup.object().shape({
    firstName: yup.string().min(2, 'First Name must contain at least two characters'),
    lastName: yup.string().min(2, 'Last Name must contain at least two characters'),
    email: yup.string().email('Invalid email').required('email is required')
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, 'Please enter a valid email'),
    userName: yup.string()
        .required('Username is required')
        .min(15, 'Username must be exactly 15 characters long')
        .max(15, 'Username must be exactly 15 characters long')
        .matches(/^[a-z0-9]+$/, 'Username must contain only lowercase letters or numbers'),
    phoneNo: yup.
        string().
        required('Phone Number is required')
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        ),
    companyId: yup.string().required('Company is required')
})


export const EditInviteClient = yup.object().shape({
    firstName: yup.string().min(2, 'First Name must contain at least two characters'),
    lastName: yup.string().min(2, 'Last Name must contain at least two characters'),
    phoneNo: yup.
        string().
        required('Phone Number is required')
        .test(
            'is-valid-format',
            'Invalid phone number format',
            function (value) {
                if (!value) return true;
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
            }
        ),
    companyId: yup.string().required('Company is required')
})


export const AssignIncidentSchema = yup.object().shape({
    assignId: yup.string().required('Field is required'),
    level: yup.string().required('Field is required')
})

export const AssignNormalShiftSchema = yup.object().shape({
    assignTo: yup.string().required('User is required'),
    break: yup.string().required('Un-paid Break is required'),
    propertyId: yup.string().required('Property is required'),
    eligibilityId: yup.string().required('Eligibility is required'),
    notes: yup.string().required('Notes is required'),
    locationId: yup.string().required('Location is required')
})

export const AssignOpenShiftSchema = yup.object().shape({
    break: yup.string().required('Un-paid Break is required'),
    numberOfAssignees: yup.string().required('Assignees Count is required'),
    propertyId: yup.string().required('Property is required'),
    eligibilityId: yup.string().required('Eligibility is required'),
    recurringFrequency: yup.string().required('Repeat Shift is required'),
    notes: yup.string().required('Notes is required'),
    locationId: yup.string().required('Location is required')
})

export const TorRequestSchema = yup.object().shape({
    userId: yup.string().required('User is required'),
    type: yup.string().required(),
    notes: yup.string().required()
})


export const OpenShiftDetail = yup.object().shape({
    usersId: yup.array().required('user is required').min(1, "Please select atleast one user")
})