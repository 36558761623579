import { GET_TOR, GET_TOR_DETAIL, GET_TOR_DETAIL_FAILURE, GET_TOR_DETAIL_SUCCESS, GET_TOR_FAILURE, GET_TOR_SUCCESS } from "../constants";

const initialState = {
    loader: false,
    detailLoader: false,
    torData: [],
    torDetailData: []
}

export default function ShiftTorReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TOR:
            state = {
                ...state,
                loader: true
            }
            break;
        case GET_TOR_SUCCESS:
            state = {
                ...state,
                loader: false,
                torData: action.payload
            }
            break;
        case GET_TOR_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;
        case GET_TOR_DETAIL:
            state = {
                ...state,
                detailLoader: true
            }
            break;
        case GET_TOR_DETAIL_SUCCESS:
            state = {
                ...state,
                detailLoader: false,
                torDetailData: action.payload
            }
            break;
        case GET_TOR_DETAIL_FAILURE:
            state = {
                ...state,
                detailLoader: false
            }
            break;

        default:
            break;
    }
    return state
}