import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import FilterBar from '../../../components/FilterBar'
import AssignIncidentDrawer from './assignIncidentDrawer';
import { Col, ConfigProvider, DatePicker, Row, Select, Spin, Tabs } from 'antd';

import { IoArrowBack } from "react-icons/io5";
import filter from '../../../assets/images/filter.png'
import TableWrapper from '../../../components/Grid';
import BasicDrawer from '../../../components/Drawer';
import Actiondropdown from '../../../components/DropDown/actiondropdown';
import { FaBan, FaEye } from 'react-icons/fa';
import { IoIosPower } from 'react-icons/io';
import { MdOutlineTaskAlt } from "react-icons/md";
import LabelWithSelect from '../../../components/LabelWithSelect';
import { Colors } from '../../../config';
import RangeDatePicker from '../../../components/Picker/RangePicker';
import moment from 'moment';
import { Button } from '../../../components';
import { ManageIncidentsAction } from '../../../store/actions';
import { useQuery } from 'react-query';
import { getAllIncidents, getAllProperties } from '../../../config/getApis';
import DateTimeComponent from '../../../config/util/DateAndTimeParser';
import dayjs from 'dayjs';
import { delay } from 'redux-saga/effects';



const ManageIncidents = () => {

    const { user } = useSelector((state) => state?.AppReducer);
    const userRole = user?.data?.userRole[0];

    const { incidentData, loader } = useSelector((state) => state?.ManageIncidentReducer);
    const { data: incidentsDataTableRecords, paginate } = incidentData;
    console.log(incidentData)

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [state, setState] = useState({
        search: '',
        limit: 10,
        page: 1,
        filterDrawer: {
            property: '',
            incidentType: '',
            status: '',
            level: '',
            startingDate: '',
            endingDate: ''
        }
    })

    console.log('state setting', state)

    useEffect(() => {
        dispatch(ManageIncidentsAction.GetManageIncidents(state))
    }, [])

    const [drawerStates, setDrawerStates] = useState({
        assignIncidentDrawer: false,
        filter: false
    })

    const { data: propertiesOptions } = useQuery('properties', getAllProperties, { enabled: drawerStates.filter });
    const { data: incidentsOptions } = useQuery('incident', getAllIncidents, { enabled: drawerStates.filter });

    const assignIncidentHandler = () => {
        setDrawerStates({ ...drawerStates, assignIncidentDrawer: true })
    }

    // creating debouce effect for search and maintaining the filters state as well.
    const debouceHandler = (query, delay) => {
        const { limit, filterDrawer } = state;
        const { property, incidentType, status, level, startingDate, endingDate } = filterDrawer;
        setState((prev) => ({ ...prev, page: 1, search: query, filterDrawer: { property: property, incidentType: incidentType, status: status, level: level, startingDate: startingDate, endingDate: endingDate } }));
        let payload = {
            search: query,
            limit: limit,
            page: 1,
            filterDrawer: {
                property: property,
                incidentType: incidentType,
                status: status,
                level: level,
                startingDate: startingDate,
                endingDate: endingDate
            }
        }
        let timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            return (
                dispatch(ManageIncidentsAction.GetManageIncidents(payload))
            )
        }, [delay])
    }

    // search handler
    const searchInputHandler = (e) => {
        let query = e?.target?.value;
        debouceHandler(query, 3000)
    }

    const onCloseDrawer = () => {
        if (drawerStates?.assignIncidentDrawer) {
            setDrawerStates({ ...drawerStates, assignIncidentDrawer: false })
        }
        else if (drawerStates.filter) {
            setDrawerStates({ ...drawerStates, filter: false })
        }
    }

    const filterHandler = () => {
        setDrawerStates({ ...drawerStates, filter: true })
    }

    const items = [
        // {
        //     label: 'Assign To',
        //     key: 1,
        //     icon: <MdOutlineTaskAlt color='#389F4F' />,
        //     backgroundColor: '#D7ECDC',
        //     color: '#389F4F',
        // },
        {
            label: 'View Details',
            key: 2,
            icon: <FaEye color='#7BA8FF' />,
            backgroundColor: '#EEF4FF',
            color: '#7BA8FF',
        }

    ]

    const actionHandler = (e, record) => {
        if (e?.key == 1) {
            return
        }

        if (e?.key == 2) {
            navigate(`/security-issues/manage-incidents/report/${record?.id}`)
        }
    }

    // filter search handler
    const incidentFilters = () => {
        const { property, incidentType, status, level, startingDate, endingDate } = state.filterDrawer;
        const { search, limit } = state;

        let values = Object.values(state.filterDrawer).some(item => item !== '');
        if (!values) {
            return
        }

        else {
            setState((prev) => ({ ...prev, page: 1, filterDrawer: { property: property, incidentType: incidentType, status: status, level: level, startingDate: startingDate, endingDate: endingDate } }));
            let payload = {
                search: search,
                limit: limit,
                page: 1,
                filterDrawer: {
                    property: property,
                    incidentType: incidentType,
                    status: status,
                    level: level,
                    startingDate: startingDate,
                    endingDate: endingDate
                }
            }
            console.log("checkkign on on clear", payload)
            dispatch(ManageIncidentsAction.GetManageIncidents(payload))
            setDrawerStates({ ...drawerStates, filter: false })
        }
    }

    // Filter states cleaner
    const clearFilters = () => {
        if (drawerStates.filter) {
            setState((prev) => ({
                ...prev,
                page: 1,
                filterDrawer: {
                    property: '',
                    incidentType: '',
                    status: '',
                    level: '',
                    startingDate: '',
                    endingDate: ''
                }
            }))
            let payload = {
                search: '',
                limit: state.limit,
                page: 1,
                filterDrawer: {
                    property: '',
                    incidentType: '',
                    status: '',
                    level: '',
                    startingDate: '',
                    endingDate: ''
                }
            }
            dispatch(ManageIncidentsAction.GetManageIncidents(payload))
            setDrawerStates({ ...drawerStates, filter: false })
        }
        return
    }


    const incidentsColumn = [
        {
            title: "Incident Id",
            dataIndex: "id",
            align: "center",
            render: (text, record) => (
                <Row>
                    <Col lg={24} className='' >
                        {record?.id}
                    </Col>
                </Row >

            )
        },
        {
            title: "Property Name",
            dataIndex: "property",
            align: "left",
            render: (text, record) => (
                <Row>
                    <Col lg={24} className='' >
                        {record?.property?.name ? record?.property?.name : '-'}
                    </Col>
                </Row >

            )
        },
        {
            title: "Reported Issue",
            dataIndex: "incidentType",
            align: "left",
            render: (text, record) => (
                <Row>
                    <Col lg={24} className='' >
                        {record?.incidentType?.name ? record?.incidentType?.name : '-'}
                    </Col>
                </Row >

            )
        },
        {
            title: "Create Date",
            dataIndex: "createdDate",
            align: "left",
            render: (text, record) => (
                <Row>
                    <Col lg={24} className='' >
                        {record?.createdDate ? DateTimeComponent(record?.createdDate, 'date') : '-'}
                    </Col>
                </Row >

            )
        },
        {
            title: "Created By",
            dataIndex: "reportedBy",
            align: "left",
            render: (text, record) => (
                <Row>
                    <Col lg={24} className='' >
                        {record?.reportedBy?.firstName ? record?.reportedBy?.firstName + ' ' + record?.reportedBy?.lastName : '-'}
                    </Col>
                </Row >

            )
        },
        {
            title: "Level",
            dataIndex: "level",
            align: "left",
            render: (text, record) => (
                <Row>
                    <Col lg={24} className='' >
                        {record?.incidentType?.level ? record?.incidentType?.level : '-'}
                    </Col>
                </Row >

            )
        },
        {
            title: "Action",
            dataIndex: "",
            align: "left",
            render: (text, record) => (
                <Row>
                    <Col lg={24} className='' >
                        <Actiondropdown items={items} onClick={(e) => actionHandler(e, record)} />
                    </Col>
                </Row >

            )
        },
    ]

    const handlePaginationChange = (page, pageSize) => {
        const { search, limit, filterDrawer } = state;
        const { property, incidentType, status, level, startingDate, endingDate } = filterDrawer;
        setState((prev) => ({ ...prev, page: page, filterDrawer: { property: property, incidentType: incidentType, status: status, level: level, startingDate: startingDate, endingDate: endingDate } }));
        let payload = {
            search: search,
            limit: limit,
            page: page,
            filterDrawer: {
                property: property,
                incidentType: incidentType,
                status: status,
                level: level,
                startingDate: startingDate,
                endingDate: endingDate
            }
        }
        dispatch(ManageIncidentsAction.GetManageIncidents(payload))
    }

    // const filterOnClearHandler = (name) => {
    //     if (name == 'level') {
    //         const { level } = state?.filterDrawer;
    //         alert('level')
    //         setState((prev) => ({ ...prev, filterDrawer: { ...prev.filterDrawer, level: level } }))
    //     }
    // }


    return (
        <>
            <FilterBar
                title={'Security Issues'}
                btnText={'Assign Incident'}
                AddUserHandler={assignIncidentHandler}
                isRole={userRole}
                isFilter={false}
                handleInputChange={searchInputHandler}
                SearchValue={state?.search}
            />

            <Row className='flex items-center gap-2 my-4'>
                <IoArrowBack className='cursor-pointer' size={18} onClick={() => navigate('/security-issues')} />
                Back
            </Row>

            <Row gutter={[8, 32]} className='w-fit sm:w-fit lg:w-fit xl:w-full' style={{ background: "white", padding: '16px', borderRadius: "10px" }}>

                <Col lg={24}>
                    <Row className='w-full'>
                        <Col lg={24}>
                            <TableWrapper
                                tableStyle={{ borderRadius: "40px", width: '100%' }}
                                headerbg={"red"}
                                tableColumns={incidentsColumn}
                                title={() => (
                                    <div className='flex items-center justify-between px-2'>
                                        <span className='text-lg font-bold'>Manage Incidents/Issues</span>
                                        <img src={filter} height={35} width={35} className='cursor-pointer' onClick={filterHandler} />
                                    </div>
                                )}
                                tableData={incidentsDataTableRecords ? incidentsDataTableRecords : []}
                                total={paginate?.total}
                                pageSize={state.limit}
                                currentPage={state.page}
                                handlePaginationChange={handlePaginationChange}
                                loading={loader && <Spin />}
                                rowClassName={'row-antd'}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row >


            <AssignIncidentDrawer
                drawerStates={drawerStates}
                setDrawerStates={setDrawerStates}
                onClose={onCloseDrawer}
            />

            <BasicDrawer
                openDrawer={drawerStates?.filter}
                onClose={onCloseDrawer}
                width={300}
                title={'Filter'}
                children={
                    <>
                        <Row gutter={[1, 16]} className='w-full h-full'>
                            <Col span={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Property</label>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Select: {
                                                optionSelectedBg: Colors.Yellow,
                                                optionActiveBg: Colors.LightGray,
                                                optionSelectedColor: Colors.White
                                            },
                                        },
                                    }}
                                >
                                    <Select
                                        allowClear={true}
                                        className='w-full h-9 outline-none rounded'
                                        value={state?.filterDrawer?.property}
                                        onChange={(value) => {
                                            if (value !== undefined && value !== null) {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        property: value
                                                    }
                                                }));
                                            } else {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        property: ""
                                                    }
                                                }));
                                            }
                                        }}
                                        options={propertiesOptions ? propertiesOptions : []}
                                    />
                                </ConfigProvider>
                            </Col>

                            <Col span={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Incident Type</label>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Select: {
                                                optionSelectedBg: Colors.Yellow,
                                                optionActiveBg: Colors.LightGray,
                                                optionSelectedColor: Colors.White
                                            },
                                        },
                                    }}
                                >
                                    <Select
                                        allowClear={true}
                                        className='w-full h-9 outline-none rounded'
                                        value={state?.filterDrawer?.incidentType}
                                        onChange={(value) => {
                                            if (value !== undefined && value !== null) {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        incidentType: value
                                                    }
                                                }));
                                            } else {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        incidentType: ""
                                                    }
                                                }));
                                            }
                                        }}
                                        options={incidentsOptions ? incidentsOptions : []}
                                    />
                                </ConfigProvider>
                            </Col>


                            <Col span={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Status</label>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Select: {
                                                optionSelectedBg: Colors.Yellow,
                                                optionActiveBg: Colors.LightGray,
                                                optionSelectedColor: Colors.White
                                            },
                                        },
                                    }}
                                >
                                    <Select
                                        allowClear={true}
                                        className='w-full h-9 outline-none rounded'
                                        value={state?.filterDrawer?.status}
                                        onChange={(value) => {
                                            if (value !== undefined && value !== null) {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        status: value
                                                    }
                                                }));
                                            } else {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        status: ""
                                                    }
                                                }));
                                            }
                                        }}
                                        options={[
                                            {
                                                value: 'pending',
                                                label: 'Pending',
                                            },
                                            {
                                                value: 'assigned',
                                                label: 'Assigned',
                                            }
                                        ]}
                                    />
                                </ConfigProvider>
                            </Col>


                            <Col span={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Level</label>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Select: {
                                                optionSelectedBg: Colors.Yellow,
                                                optionActiveBg: Colors.LightGray,
                                                optionSelectedColor: Colors.White
                                            },
                                        },
                                    }}
                                >
                                    <Select
                                        allowClear={true}
                                        className='w-full h-9 outline-none rounded'
                                        value={state?.filterDrawer?.level}
                                        onChange={(value) => {
                                            if (value !== undefined && value !== null) {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        level: value
                                                    }
                                                }));
                                            } else {
                                                setState(prev => ({
                                                    ...prev,
                                                    filterDrawer: {
                                                        ...prev.filterDrawer,
                                                        level: ""
                                                    }
                                                }));
                                            }
                                        }}
                                        options={[
                                            {
                                                value: '3',
                                                label: 'High',
                                            },
                                            {
                                                value: '1',
                                                label: 'Low',
                                            },
                                            {
                                                value: '2',
                                                label: 'Medium',
                                            }

                                        ]}
                                    />
                                </ConfigProvider>
                            </Col>

                            <Col span={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>Date Range</label>
                                <RangeDatePicker
                                    allowClear={true}
                                    format={'YYYY-MM-DD'}
                                    defaultValue={state?.filterDrawer?.startingDate ? [dayjs(state?.filterDrawer?.startingDate), dayjs(state?.filterDrawer?.endingDate)] : [dayjs(), dayjs()]}
                                    // onChange={(dates, dateStrings) => setState((prev) => ({ ...prev, filterDrawer: { ...prev.filterDrawer, startingDate: dateStrings[0], endingDate: dateStrings[1] } }))}
                                    onChange={(dates, dateStrings) => {
                                        if (dateStrings[0]) {
                                            setState(prev => ({
                                                ...prev,
                                                filterDrawer: {
                                                    ...prev.filterDrawer,
                                                    startingDate: dateStrings[0],
                                                    endingDate: dateStrings[1]
                                                }
                                            }));
                                        } else {
                                            setState(prev => ({
                                                ...prev,
                                                filterDrawer: {
                                                    ...prev.filterDrawer,
                                                    startingDate: '',
                                                    endingDate: ''
                                                }
                                            }));
                                        }
                                    }}
                                    // disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                />
                            </Col>

                            <Row className='w-full flex justify-between gap-4 px-2' align={'middle'}>
                                <Col span={11}>
                                    <Button.Basic onClick={incidentFilters} style={{ color: 'white', backgroundColor: Colors.Yellow }} text={'Search'} className={'w-full rounded'} size={'large'} />
                                </Col>
                                <Col span={11}>
                                    <Button.Basic onClick={clearFilters} style={{ color: 'white', backgroundColor: Colors.Error }} text={'Clear'} className={'w-full rounded'} size={'large'} />
                                </Col>
                            </Row>
                        </Row>
                    </>
                }
            />

        </>
    )
}

const data = [
    {
        id: 1,
        name: 'zaid',
        issue: 'technical',
        createdDate: '12/12/2024',
        createdBy: 'zaid',
        level: 'high'
    }
]

export default ManageIncidents