import html2pdf from 'html2pdf.js';

export const convertToPdf = (contentRef) => {
    const content = contentRef.current;

    const options = {
        filename: 'my-document.pdf',
        margin: [0.5, 0, 0.5, 0],
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
            unit: 'in',
            format: 'letter',
            orientation: 'portrait',
        },
    };
    html2pdf().set(options).from(content).save();
};
