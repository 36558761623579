// AUTH CONSTANTS ===============================================================================
export const SIGNIN = "SIGNIN"
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS"
export const SIGNIN_FAILURE = "SIGNIN_FAILURE"

export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION"
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS"
export const EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE"

export const OTP_VERIFICATION = "OTP_VERIFICATION"
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS"
export const OTP_VERIFICATION_FAILURE = "OTP_VERIFICATION_FAILURE"

export const PASSWORD_RESET = "PASSWORD_RESET"
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE"

export const LOGOUT = "LOGOUT"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"

// USERS MANAGEMENT CONSTANTS =============================================================================
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAILURE = "GET_USERS_FAILURE"

export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAILURE = "ADD_USER_FAILURE"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"

export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAILURE = "GET_USER_DETAIL_FAILURE"

export const USER_DETAIL_RESET = 'USER_DETAIL_RESET'

export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS'
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS'
export const UPDATE_USER_STATUS_FAILURE = 'UPDATE_USER_STATUS_FAILURE'

//site-management
export const GET_PROPERTY = "GET_PROPERTY"
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS"
export const GET_PROPERTY_FAILURE = "GET_PROPERTY_FAILURE"

export const UPDATE_PROPERTY_STATUS = "GET_PROPERTY_STATUS"


export const GET_PROPERTY_DETAIL = "GET_PROPERTY_DETAIL"
export const GET_PROPERTY_DETAIL_SUCCESS = "GET_PROPERTY_DETAIL_SUCCESS"
export const GET_PROPERTY_DETAIL_FAILURE = "GET_PROPERTY_DETAIL_FAILURE"


export const ADD_PROPERTY = "ADD_PROPERTY_DETAIL"
export const ADD_PROPERTY_SUCCESS = "ADD_PROPERTY_SUCCESS"
export const ADD_PROPERTY_FAILURE = "ADD_PROPERTY_FAILURE"

export const EDIT_PROPERTY = "EDIT_PROPERTY"
export const EDIT_PROPERTY_SUCCESS = "EDIT_PROPERTY_SUCCESS"
export const EDIT_PROPERTY_FAILURE = "EDIT_PROPERTY_FAILURE"

export const GET_PROPERTIES = 'GET_PROPERTIES'
export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS'
export const GET_PROPERTIES_FAILURE = 'GET_PROPERTIES_FAILURE'

export const ADD_ADDRESS = "ADD_ADDRESS"
export const EDIT_ADDRESS = "EDIT_ADDRESS"

export const GET_ADDRESS = 'GET_ADDRESS'
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS'
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE'
export const GET_ADDRESS_DATA_CLEANER = 'GET_ADDRESS_DATA_CLEANER'

export const ADD_DOCUMENT = "ADD_DOCUMENT"
export const EDIT_DOCUMENT = "EDIT_DOCUMENT"

export const GET_DOCUMENT = 'GET_DOCUMENT'
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS'
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE'
export const GET_DOCUMENT_DATA_CLEANER = 'GET_DOCUMENT_DATA_CLEANER'

export const ADD_LOCATION = "ADD_LOCATION"
export const EDIT_LOCATION = "EDIT_LOCATION"
export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE'
export const LOCATION_DATA_CLEANER = 'LOCATION_DATA_CLEANER'

export const ADD_INCIDENT = "ADD_INCIDENT"
export const EDIT_INCIDENT = "EDIT_INCIDENT"

export const GET_INCIDENT_TYPES = 'GET_INCIDENT_TYPES'
export const GET_INCIDENT_TYPES_SUCCESS = 'GET_INCIDENT_TYPES_SUCCESS'
export const GET_INCIDENT_TYPES_FAILURE = 'GET_INCIDENT_TYPES_FAILURE'
export const ADD_PROPERTY_INCIDENT = 'ADD_PROPERTY_INCIDENT'
export const ADD_PROPERTY_INCIDENT_SUCCESS = 'ADD_PROPERTY_INCIDENT_SUCCESS'
export const ADD_PROPERTY_INCIDENT_FAILURE = 'ADD_PROPERTY_INCIDENT_FAILURE'

export const ADD_CHECKPOINT = "ADD_CHECKPOINT"
export const EDIT_CHECKPOINT = "EDIT_CHECKPOINT"

export const GET_CHECKPOINTS = 'GET_CHECKPOINTS'
export const GET_CHECKPOINTS_SUCCESS = 'GET_CHECKPOINTS_SUCCESS'
export const GET_CHECKPOINTS_FAILURE = 'GET_CHECKPOINTS_FAILURE'
export const GET_CHECKPOINTS_CLEANER = 'GET_CHECKPOINTS_CLEANER'

export const ADD_GEOFENCE = "ADD_GEOFENCE"
export const EDIT_GEOFENCE = "EDIT_GEOFENCE"

export const GET_GEOFENCE = 'GET_GEOFENCE'
export const GET_GEOFENCE_SUCCESS = 'GET_GEOFENCE_SUCCESS'
export const GET_GEOFENCE_FAILURE = 'GET_GEOFENCE_FAILURE'
export const GET_GEOFENCE_DATA_CLEANER = 'GET_GEOFENCE_DATA_CLEANER'

export const ADD_PHONE = "ADD_PHONE"
export const EDIT_PHONE = "EDIT_PHONE"

export const ADD_ADDRESS_TYPE = 'ADD_ADDRESS_TYPE'
export const ADD_ADDRESS_TYPE_SUCCESS = 'ADD_ADDRESS_TYPE_SUCCESS'


// Client Management
export const GET_INVITED_CLIENTS = 'GET_INVITED_CLIENTS'
export const GET_INVITED_CLIENTS_SUCCESS = 'GET_INVITED_CLIENTS_SUCCESS'
export const GET_INVITED_CLIENTS_FAILURE = 'GET_INVITED_CLIENTS_FAILURE'

export const GET_INVITED_CLIENT_DETAIL = 'GET_INVITED_CLIENT_DETAIL'
export const GET_INVITED_CLIENT_DETAIL_SUCCESS = 'GET_INVITED_CLIENT_DETAIL_SUCCESS'
export const GET_INVITED_CLIENT_DETAIL_FAILURE = 'GET_INVITED_CLIENT_DETAIL_FAILURE'

export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE'

export const GET_COMPANIES_DETAIL = 'GET_COMPANIES_DETAIL'
export const GET_COMPANIES_DETAIL_SUCCESS = 'GET_COMPANIES_DETAIL_SUCCESS'
export const GET_COMPANIES_DETAIL_FAILURE = 'GET_COMPANIES_DETAIL_FAILURE'

export const COMPANIES_STATUS_UPDATE = 'COMPANIES_STATUS_UPDATE'
export const COMPANIES_STATUS_UPDATE_SUCCESS = 'COMPANIES_STATUS_UPDATE_SUCCESS'
export const COMPANIES_STATUS_UPDATE_FAILURE = 'COMPANIES_STATUS_UPDATE_FAILURE'

// Settings
export const GET_SCHEDULES = 'GET_SCHEDULES'
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS'
export const GET_SCHEDULES_FAILURE = 'GET_SCHEDULES_FAILURE'

export const GET_SCHEDULES_DETAIL = 'GET_SCHEDULES_DETAIL'
export const GET_SCHEDULES_DETAIL_SUCCESS = 'GET_SCHEDULES_DETAIL_SUCCESS'
export const GET_SCHEDULES_DETAIL_FAILURE = 'GET_SCHEDULES_DETAIL_FAILURE'

export const GET_ELEGIBILITIES = 'GET_ELEGIBILITIES'
export const GET_ELEGIBILITIES_SUCCESS = 'GET_ELEGIBILITIES_SUCCESS'
export const GET_ELEGIBILITIES_FAILURE = 'GET_ELEGIBILITIES_FAILURE'

export const LOADER_TRUE = 'LOADER_TRUE';
export const LOADER_FALSE = 'LOADER_FALSE';

// MANAGE INCIDENTS
export const GET_MANAGE_INCIDENTS = 'GET_MANAGE_INCIDENTS'
export const GET_MANAGE_INCIDENTS_SUCCESS = 'GET_MANAGE_INCIDENTS_SUCCESS'
export const GET_MANAGE_INCIDENTS_FAILURE = 'GET_MANAGE_INCIDENTS_FAILURE'

export const GET_MANAGE_INCIDENTS_DETAIL = 'GET_MANAGE_INCIDENTS_DETAIL'
export const GET_MANAGE_INCIDENTS_DETAIL_SUCCESS = 'GET_MANAGE_INCIDENTS_DETAIL_SUCCESS'
export const GET_MANAGE_INCIDENTS_DETAIL_FAILURE = 'GET_MANAGE_INCIDENTS_DETAIL_FAILURE'

export const GET_ASSIGN_INCIDENT_DETAIL = 'GET_ASSIGN_INCIDENT_DETAIL'
export const GET_ASSIGN_INCIDENT_DETAIL_SUCCESS = 'GET_ASSIGN_INCIDENT_DETAIL_SUCCESS'
export const GET_ASSIGN_INCIDENT_DETAIL_FAILURE = 'GET_ASSIGN_INCIDENT_DETAIL_FAILURE'
export const GET_ASSIGN_INCIDENT_DETAIL_CLEANER = 'GET_ASSIGN_INCIDENT_DETAIL_CLEANER'


// SHIFT SCHEDULING
export const GET_POTENTIAL_TAKERS = 'GET_POTENTIAL_TAKERS'
export const GET_POTENTIAL_TAKERS_SUCCESS = 'GET_POTENTIAL_TAKERS_SUCCESS'
export const GET_POTENTIAL_TAKERS_FAILURE = 'GET_POTENTIAL_TAKERS_FAILURE'

export const GET_SHIFTS = 'GET_SHIFTS'
export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS'
export const GET_SHIFTS_FAILURE = 'GET_SHIFTS_FAILURE'


// Attendence Management
export const GET_ATTENDENCE = 'GET_ATTENDENCE'
export const GET_ATTENDENCE_SUCCESS = 'GET_ATTENDENCE_SUCCESS'
export const GET_ATTENDENCE_FAILURE = 'GET_ATTENDENCE_FAILURE'

export const GET_ATTENDENCE_PROPERTIES = 'GET_ATTENDENCE_PROPERTIES'
export const GET_ATTENDENCE_PROPERTIES_SUCCESS = 'GET_ATTENDENCE_PROPERTIES_SUCCESS'
export const GET_ATTENDENCE_PROPERTIES_FAILURE = 'GET_ATTENDENCE_PROPERTIES_FAILURE'

export const GET_ATTENDENCE_SCHEDULES = 'GET_ATTENDENCE_SCHEDULES'
export const GET_ATTENDENCE_SCHEDULES_SUCCESS = 'GET_ATTENDENCE_SCHEDULES_SUCCESS'
export const GET_ATTENDENCE_SCHEDULES_FAILURE = 'GET_ATTENDENCE_SCHEDULES_FAILURE'

export const GET_ATTENDENCE_USERS = 'GET_ATTENDENCE_USERS' 
export const GET_ATTENDENCE_USERS_SUCCESS = 'GET_ATTENDENCE_USERS_SUCCESS'
export const GET_ATTENDENCE_USERS_FAILURE = 'GET_ATTENDENCE_USERS_FAILURE'

export const CLEAR_ATTENDENCE_FILTER = 'CLEAR_ATTENDENCE_FILTER'
export const CLEAR_ATTENDENCE = 'CLEAR_ATTENDENCE'

//TOR
export const GET_TOR = 'GET_TOR' 
export const GET_TOR_SUCCESS = 'GET_TOR_SUCCESS' 
export const GET_TOR_FAILURE = 'GET_TOR_FAILURE' 

export const GET_TOR_DETAIL = 'GET_TOR_DETAIL'
export const GET_TOR_DETAIL_SUCCESS = 'GET_TOR_DETAIL_SUCCESS'
export const GET_TOR_DETAIL_FAILURE = 'GET_TOR_DETAIL_FAILURE'
