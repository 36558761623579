import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Select, Text } from "../../components";
import { AppAction } from "../../store/actions";
import styles from "./styles"
import { Row, Col, Avatar } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { message } from 'antd';
import { ApiCaller, Colors, Utils } from "../../config";
import TextField from "../../components/TextField";
import DropDown from "../../components/DropDown";
import './style.css'
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    ReferenceLine,
    Area
} from "recharts";
import DoughnutChart from "../../components/Charts/DoughnutChart";


import { HiOutlineBellAlert } from "react-icons/hi2";
import { IoIosSearch } from "react-icons/io";
import { UserOutlined } from "@ant-design/icons";
import { IoIosArrowForward } from "react-icons/io";
import dash1 from '../../assets/images/dash1.png'
import dash2 from '../../assets/images/dash2.png'
import dash3 from '../../assets/images/dash3.png'
import dash4 from '../../assets/images/dash4.png'
import dashboard from '../../assets/images/dashbaord.jpg'
// import second from '../../assets/images/'
import BasicText from "../../components/Text/BasicText";
import SelectField from "../../components/TextField/SelectField";
import RangeDatePicker from "../../components/Picker/RangePicker";
import LoadOnScroll from "../../components/LoadOnScroll";
import Circular from "../../components/Loader/Circular";



const Dashboard = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user)

    const navigate = useNavigate();

    const options = [
        {
            label: 'My Availability',
            value: '1'
        },
        {
            label: 'Edit Profile',
            value: '2'
        },
        {
            label: 'Change Password',
            value: '3'
        },
    ]

    const month = [
        {
            label: 'jan',
            value: '1'
        },
        {
            label: 'feb',
            value: '2'
        },
    ]


    const dispatch = useDispatch()

    // console.log("ffff", isLoading, error);

    const dropDownSelector = (e) => {
        if (e.key === "tmp-0") {
            navigate('/MyAvailability')
            return
        }
        else if (e.key === "tmp-1") {
            navigate('/EditProfile')
            return
        }
        else if (e.key === 'tmp-2') {
            console.log(e.key)
            navigate('/ChangePassword')
            return
        }
    }

    // line chart data
    const data = [
        {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
        },
        {
            "name": "Page C",
            "uv": 2000,
            "pv": 9800,
            "amt": 2290
        },
        {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
        },
        {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
        },
        {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800,
            "amt": 2500
        },
        {
            "name": "Page G",
            "uv": 3490,
            "pv": 4300,
            "amt": 2100
        }
    ]

    const doughnutData = {
        labels: ['Location 1', 'Location 2', 'Location 3', 'Location 4'],
        datasets: [
            {
                data: [30, 20, 15, 15],
                backgroundColor: ['#FF8F6B', '#5B93FF', '#FFD66B', '#F7F9FF'],
                borderWidth: 1,
            },
        ],
    };


    return (
        <>
            <Row className="w-full flex items-start flex-wrap mb-8">

                <Col lg={6} md={6} sm={24} style={{}} className="my-2">
                    <h1 className='text-xl font-semibold'>Dashboard</h1>
                </Col>

                <Col lg={18} md={18} sm={24} className="flex justify-end items-center py-3 gap-4 flex-wrap" style={{}}>
                    <TextField.Basic placeholder={'Search here'} suffix={<IoIosSearch />} style={{ background: '#EFF2F5', border: '1px solid red' }} className='w-8/12 py-3' />
                    <div className="py-3 px-3 rounded-md" style={{
                        background: Colors.White,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
                    }}>
                        <Link to={'/alert'}>
                            <HiOutlineBellAlert size={20} />
                        </Link>
                    </div>

                    <div className="py-2 px-4 rounded-md" style={{
                        background: Colors.White,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
                    }}>

                        <DropDown placement={'bottom'} items={options} onClick={(e) => dropDownSelector(e)} children={
                            <div className="flex items-center gap-2 justify-between">
                                <div>
                                    <Avatar icon={<UserOutlined />} />
                                </div>
                                <div className="flex">
                                    <span>{user?.data?.firstName.slice(0, 5) + ' ' + user?.data?.lastName.slice(0, 5)}<IoIosArrowForward className="inline" /></span>
                                </div>
                            </div>
                        } />
                    </div>
                </Col>
            </Row>

            <Row className="flex items-center sm:justify-between gap-3">
                <Col lg={5} md={5} sm={10} xs={24} className="flex items-center py-5 px-6 gap-3 rounded-lg mb-2" style={{
                    background: Colors.White,
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                }}>
                    <img src={dash1} width={50} height={50} />
                    <div className="flex flex-col items-start justify-start">
                        <p className="text-2xl font-bold">178</p>
                        <p className="text-sm" style={{ color: Colors.LabelColor }}>Pending Request</p>
                    </div>
                </Col>

                <Col lg={5} md={5} sm={10} xs={24} className="flex items-center py-5 px-6 gap-3 rounded-lg mb-2" style={{
                    background: Colors.White,
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                }}>
                    <img src={dash2} width={50} height={50} />
                    <div className="flex flex-col items-start justify-center">
                        <p className="text-2xl font-bold">178</p>
                        <p className="text-sm" style={{ color: Colors.LabelColor }}>Open Shifts</p>
                    </div>
                </Col>

                <Col lg={5} md={5} sm={10} xs={24} className="flex items-center py-5 px-6 gap-3 rounded-lg mb-2" style={{
                    background: Colors.White,
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                }}>
                    <img src={dash3} width={50} height={50} />
                    <div className="flex flex-col items-start justify-center">
                        <p className="text-2xl font-bold">178</p>
                        <p className="text-sm" style={{ color: Colors.LabelColor }}>Live Tracking</p>
                    </div>
                </Col>

                <Col lg={5} md={5} sm={10} xs={24} className="flex items-center py-5 px-6 gap-3 rounded-lg mb-2" style={{
                    background: Colors.White,
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                }}>
                    <img src={dash4} width={50} height={50} />
                    <div className="flex flex-col items-start justify-center">
                        <p className="text-2xl font-bold">178</p>
                        <p className="text-sm" style={{ color: Colors.LabelColor }}>Shift For Today</p>
                    </div>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <div className="w-full">
                        <img src={dashboard} className="w-full " />
                    </div>
                </Col>
            </Row>

            <Row className="w-full flex items-start justify-between my-6 h-ful0">

                {/* // Incident Reports Line Chart */}
                <Col xl={15} lg={24} md={24} sm={24} xs={24} className="py-3 px-3 rounded-lg self-start h-full mb-3" style={{
                    background: Colors.White,
                    height: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'

                }}>
                    <Row className="w-full flex items-center justify-between px-1 py-2 mb-5" >
                        <Col lg={8}>
                            <BasicText text={'Incident Reports'} className='text-base font-semibold' />
                        </Col>
                        <Col lg={16} className="flex items-center justify-between gap-1" style={{}}>
                            <SelectField placeholder={'Monthly'} className={'w-1/2'} options={month} />
                            <RangeDatePicker className='w-1/2' />
                        </Col>
                    </Row>

                    <Row className="w-full h-full" style={{}}>
                        <Col lg={24} md={24} sm={24} xs={24} style={{}}>
                            <ResponsiveContainer width={'100%'} height={295}>
                                <LineChart data={data}
                                    margin={{ top: 15, bottom: 15 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="pv" stroke="#EEB600" strokeWidth={2} isAnimationActive={true} animationBegin={2} animationEasing="ease-in-out" dot={{ stroke: '#EEB600', strokeWidth: 2 }} />
                                    {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                                </LineChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </Col>

                {/* // Incident Analytics Doughnut Chart */}
                <Col xl={8} lg={24} md={24} sm={24} xs={24} className="rounded-lg p-3 h-full" style={{
                    height: '100%',
                    background: Colors.White,
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
                }}>
                    <Row className="w-full flex items-center justify-between px-1 py-2 mb-3" style={{}}>
                        <Col lg={11} md={15} sm={15} xs={12}>
                            <BasicText text={'Incident Analytics'} className='text-base font-semibold' />
                        </Col>
                        <Col lg={13} md={9} sm={9} xs={12} className="flex justify-end" >
                            <SelectField placeholder={'Monthly'} className={'w-9/12	'} options={month} />
                        </Col>
                    </Row>
                    <Row className="w-full flex items-center justify-center relative" >
                        <Col className="h-full">
                            {/* <span className="lg:inline absolute resp-text lg:text-xl lg:font-bold lg:top-32 sm:font-bold sm:text-xl xs:text-xl xs:font-bold sm:top-32 top-14 text-xs" style={{ color: Colors.Black }}>80% Job Done</span> */}
                            <DoughnutChart data={doughnutData} />
                        </Col>
                    </Row>

                </Col>
            </Row>

        </>
    )
}
export default Dashboard;