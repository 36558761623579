import { combineReducers } from 'redux';
import AppReducer from './AppReducer';
import UserManagementReducer from './UserManagementReducer';
import SiteManagementReducer from './SiteManagementReducer';
import ClientManagementReducer from './ClientManagementReducer';
import SettingReducer from './SettingReducer';
import ManageIncidentReducer from './ManageIncidentsReducer';
import ShiftSchedulerReducer from './ShiftSchedulerReducer';
import AttendenceManagementReducer from './AttendenceManagementReducer';
import ShiftTorReducer from './ShiftTorReducer';

const RootReducer = combineReducers({
    AppReducer,
    UserManagementReducer,
    SiteManagementReducer,
    ClientManagementReducer,
    SettingReducer,
    ManageIncidentReducer,
    ShiftSchedulerReducer,
    AttendenceManagementReducer,
    ShiftTorReducer

});

export default RootReducer;