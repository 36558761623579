import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Divider, Image, Space } from 'antd'
import { IoArrowBack } from 'react-icons/io5';
import { Button, Text, Modal } from '../../../components';
import { Colors } from '../../../config';
import videoplayback from '../../../assets/images/videoplayback.mp4'
import printer from '../../../assets/images/printer.png'
import videoThumbnail from '../../../assets/images/videoThumbnail.jpg'
import building from '../../../assets/images/building.png'
import { useDispatch, useSelector } from 'react-redux';
import { ManageIncidentsAction } from '../../../store/actions';
import Circular from '../../../components/Loader/Circular';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import VideoPlayer from '../../../components/videoPlayer';
import DateTimeComponent from '../../../config/util/DateAndTimeParser';

const IncidentDetail = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { detailLoader, incidentDetailData } = useSelector(state => state?.ManageIncidentReducer);
    console.log(incidentDetailData)


    useEffect(() => {
        dispatch(ManageIncidentsAction.GetManageIncidentDetail(id))
    }, [])

    let imageFormats = ['png', 'jpg', 'jpeg'];
    let videoFormats = ['mp4']

    return (
        <>


            {
                detailLoader ?
                    <Circular
                        spinning={detailLoader}
                        size={40}
                        color={Colors.Yellow}
                        containerStyle={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    />
                    :
                    <>
                        <Row gutter={[16, 20]} className='mb-10 mt-2'>
                            <Col span={24}>
                                <h3 className='text-xl font-bold'>Reporting / Analytics</h3>
                            </Col>
                            <Col span={24}>
                                <IoArrowBack className='cursor-pointer inline ' size={18} onClick={() => navigate('/security-issues/manage-incidents')} />
                                <span className='ml-2'>Back</span>
                            </Col>
                        </Row>

                        <Row className='rounded-lg py-6 px-4 mb-4' style={{ background: Colors.White }}>
                            <Col lg={24} xl={24} className='flex items-center justify-between w-full flex-wrap'>
                                <div className='flex gap-1 flex-wrap'>
                                    <Text.Basic fontSize='18' fontWeight='700' text={`Issue# ${incidentDetailData?.id ? incidentDetailData?.id : '-'}`} />
                                    <Text.Basic fontSize='18' color={Colors.Error} fontWeight='700' text={`(Level ${incidentDetailData?.level ? incidentDetailData?.level : '-'})`} />
                                </div>
                                <img className='cursor-pointer' onClick={() => window.print()} src={printer} height={35} width={35} />
                            </Col>
                            <Col span={24}>
                                <Text.Basic fontSize='14' fontWeight='600' text={'Property Details'} />
                            </Col>
                            <Divider />

                            <Row className='w-full' gutter={[16, 16]}>
                                <Col lg={8} md={8} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Property Name'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.property?.name ? incidentDetailData?.property?.name : '-'}</p>
                                </Col>
                                <Col lg={8} md={8} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Property Address'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.address ? `${incidentDetailData?.address?.streetNumber}, ${incidentDetailData?.address?.streetName}, ${incidentDetailData?.address?.city} ${incidentDetailData?.address?.zip}` : '-'}</p>
                                </Col>
                                <Col lg={8} md={8} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Location'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.address ? `${incidentDetailData?.address?.streetNumber}, ${incidentDetailData?.address?.streetName}, ${incidentDetailData?.address?.city} ${incidentDetailData?.address?.zip}` : '-'}</p>
                                </Col>
                            </Row>
                            <Divider />

                            <Row className='w-full' gutter={[16, 16]}>
                                <Col lg={8} md={8} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Reported Address'}
                                    />

                                    <p className='text-xs break-all'>{incidentDetailData?.address ? `${incidentDetailData?.address?.streetNumber}, ${incidentDetailData?.address?.streetName}, ${incidentDetailData?.address?.city} ${incidentDetailData?.address?.zip}` : '-'}</p>
                                </Col>
                                <Col lg={8} md={8} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Actual Address/Unit'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.unit ? incidentDetailData?.unit : '-'}</p>
                                </Col>
                                <Col lg={8} md={8} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Floor'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.floor ? incidentDetailData?.floor : '-'}</p>
                                </Col>
                            </Row>
                        </Row>

                        <Row className='rounded-lg py-6 px-4 mb-4' style={{ background: Colors.White }}>
                            <Col lg={24} xl={24} className='flex items-center justify-between'>
                                <div className='flex gap-1'>
                                    <Text.Basic fontSize='18' fontWeight='700' text={`Created Details`} />
                                </div>
                            </Col>

                            <Divider />

                            <Row className='w-full' gutter={[16, 16]}>
                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Approx Occurance Time'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.createdDate ? DateTimeComponent(incidentDetailData?.createdDate, 'time') : '-'}</p>
                                </Col>
                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Create Date'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.createdDate ? DateTimeComponent(incidentDetailData?.createdDate, 'date') : '-'}</p>
                                </Col>
                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Created By'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.reportedBy?.firstName ? incidentDetailData?.reportedBy?.firstName + ' ' + incidentDetailData?.reportedBy?.lastName : '-'}</p>
                                </Col>
                                <Col lg={6} md={6} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Assigned By'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.assignedBy?.first_name ? incidentDetailData?.assignedBy?.first_name + ' ' + incidentDetailData?.assignedBy?.last_name : '-'}</p>
                                </Col>
                            </Row>
                            <Divider />

                        </Row>

                        <Row className='rounded-lg py-6 px-4 mb-4' style={{ background: Colors.White }}>
                            <Col lg={24} xl={24} className='flex items-center justify-between'>
                                <div className='flex gap-1'>
                                    <Text.Basic fontSize='18' fontWeight='700' text={`Assigned Details`} />
                                </div>
                            </Col>

                            <Divider />

                            <Row className='w-full' gutter={[16, 16]}>
                                <Col lg={5} md={5} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Acknowledged By'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.assignedTo?.firstName ? incidentDetailData?.assignedTo?.firstName + ' ' + incidentDetailData?.assignedTo?.lastName : '-'}</p>
                                </Col>
                                <Col lg={5} md={5} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Acknowledged Date'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.acknowledgedDate ? incidentDetailData?.acknowledgedDate : '-'}</p>
                                </Col>
                                <Col lg={5} md={5} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Arrived User'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.assignedTo?.firstName ? incidentDetailData?.assignedTo?.firstName + ' ' + incidentDetailData?.assignedTo?.lastName : '-'}</p>
                                </Col>
                                <Col lg={5} md={5} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Arrived Date'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.assignedDate ? DateTimeComponent(incidentDetailData?.assignedDate, 'date') : '-'}</p>
                                </Col>
                                <Col lg={4} md={4} sm={24} xs={24}>
                                    <Text.Label
                                        style={{ color: Colors.LabelColor }}
                                        text={'Status'}
                                    />
                                    <p className='text-xs break-all'>{incidentDetailData?.assignedTo?.status ? incidentDetailData?.assignedTo?.status : '-'}</p>
                                </Col>
                            </Row>
                            <Divider />
                        </Row>

                        <Row className='mb-4'>
                            <Text.Basic fontSize='14' fontWeight='500' text={`Photos`} />
                            <Row className='w-full flex gap-2'>
                                {
                                    incidentDetailData?.incidentAttachments?.length > 0 ?
                                        incidentDetailData?.incidentAttachments?.map((attachment, index) => {
                                            if (attachment?.format == 'png' || attachment?.format == 'jpg' || attachment?.format == 'jpeg') {
                                                return <Col key={index} lg={3} xl={3} md={6} sm={6} xs={12} className=''>
                                                    <Image
                                                        src={attachment?.url}
                                                        height='100%'
                                                        width='100%'
                                                    />
                                                </Col>
                                            }
                                            else if (attachment?.format == 'mp4') {
                                                return <Col key={index} lg={3} xl={3} md={6} sm={6} xs={12}>
                                                    <VideoPlayer posterSrc={videoThumbnail} videoSrc={attachment?.url} />
                                                </Col>
                                            }
                                        })

                                        : ''
                                }

                                {/* <Col lg={3} xl={3} md={6} sm={6} xs={12}>
                                    <VideoPlayer posterSrc={'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'} videoSrc={videoplayback} />
                                </Col>
                                <Col lg={3} xl={3} md={6} sm={6} xs={12}>
                                    <VideoPlayer posterSrc={'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'} videoSrc={videoplayback} />
                                </Col> */}
                            </Row>
                        </Row>

                        <Row className='mb-4'>
                            <Col lg={24} className='flex items-center justify-end gap-3 flex-wrap'>
                                {/* <Button.Basic text={'Add Note'} className={'rounded-md px-6 h-12'} size={'large'} buttonStyle={{ color: 'white', background: Colors.Yellow }} /> */}
                                <Button.Basic text={'Close'} onClick={() => navigate('/security-issues/manage-incidents')} className={'rounded-md px-6 h-12'} size={'large'} buttonStyle={{ color: 'white', background: Colors.Error }} />
                            </Col>
                        </Row>
                    </>
            }

            {/* <Modal.CustomModal
                open={modal}
                title={'Incident Media'}
                cancelModal={() => setModal(false)}
                Children={
                    <>
                        <img src={modalData} style={{width:'100%'}} />
                    </>
                }
            /> */}



        </>
    )
}

export default IncidentDetail