import React, { useState, useEffect } from 'react'
import FilterBar from '../../../components/FilterBar'
import { useDispatch, useSelector } from 'react-redux';
import { Col, ConfigProvider, Row, Select, Tabs } from 'antd';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import BasicDrawer from '../../../components/Drawer';
import BasicText from '../../../components/Text/BasicText';
import { Colors } from '../../../config';
import AssignShiftDrawer from './assignShiftDrawer';
import filter from '../../../assets/images/filter.png'
import { Button, Pagination } from '../../../components';
import App from './App';
import moment from 'moment';
import { ShiftSchedulerAction } from '../../../store/actions';
import Circular from '../../../components/Loader/Circular';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { getAllProperties, getAllUsers, getEligibiity, getSchedules } from '../../../config/getApis';
import avatar from '../../../assets/images/avatar.jpg'
import DetailShiftDrawer from './detailShiftDrawer';

const ShiftScheduler = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user } = useSelector((state) => state?.AppReducer);
    const userRole = user?.data?.userRole[0];

    const { loader, shiftsData } = useSelector((state) => state?.ShiftSchedulerReducer);
    const { data: shiftRecords, paginate } = shiftsData;
    console.log('shifts records data', shiftRecords)

    const [state, setState] = useState({
        limit: 5,
        page: 1,
        tabKey: 0,
        filterDrawer: {
            schedule: '',
            user: '',
            eligiblity: '',
            confirmedShifts: ''
        }
    })

    const [openShiftDetail, setOpenShiftDetail] = useState(null);

    useEffect(() => {
        dispatch(ShiftSchedulerAction.GetShifts(state))
    }, [])

    const [drawerStates, setDrawerStates] = useState({
        assignShiftDrawer: false,
        shiftTypeDrawer: false,
        typeOfShift: '',
        filter: false,
        openShiftDetailDrawer: false
    })

    const [resources, setResources] = useState([]);
    const [events, setEvents] = useState([]);

    const shiftDetailHandler = (openShiftDetail) => {
        setDrawerStates({ ...drawerStates, openShiftDetailDrawer: true })
        setOpenShiftDetail(openShiftDetail)
    }

    useEffect(() => {
        setResources([])
        setEvents([])
        if (shiftRecords?.length > 0 || shiftRecords?.length == 0) {

            let resources = [];
            let events = [];

            for (let item of shiftRecords) {

                let appliedUsers = item?.shiftUser.filter((item) => {
                    return item?.status == 'applied'
                })

                let acceptedUsers = item?.shiftUser.filter((item) => {
                    return item?.status == 'accepted'
                })

                let noOfAssigness = item?.numberOfAssignees;

                const startDate = item.shiftDate !== null ? item.shiftDate : item.startDate;
                const endDate = item.shiftDate !== null ? item.shiftDate : item.endDate;

                const startTimeString = `${startDate} ${item.startTime}`;
                const endTimeString = `${endDate} ${item.endTime}`;

                const startTimeMoment = moment(startTimeString, "YYYY-MM-DDThh:mm:ss");
                const endTimeMoment = moment(endTimeString, "YYYY-MM-DDThh:mm:ss");

                if (item?.shiftUser?.length > 0) {
                    for (let Users of item?.shiftUser) {
                        if (state?.tabKey == 0) {
                            resources?.push({
                                id: Users?.id,
                                name: Users?.user?.firstName + " " + Users?.user?.lastName,
                                image: Users?.profileImg ? Users?.profileImg[0]?.url : avatar
                            });

                            events.push({
                                id: item?.id,
                                break: item?.break,
                                howMany: item?.howMany,
                                start: startTimeMoment,
                                end: endTimeMoment,
                                property: item?.property ? item?.property?.name : '',
                                resourceId: Users?.id,
                                title: startTimeString + " - " + endTimeString,
                                bgColor: Users?.status == 'accepted' ? "#5fdba7" : "#EEB600",
                                location: item?.address ? `${item.address?.streetNumber},${item.address?.streetName},${item.address?.city},${item.address?.buildingNumber}` : '',
                                Eligibility: item?.eligibilitity ? item?.eligibilitity?.name : '',
                                Schedules: Users?.user?.schedules[0]?.name + '...',
                                assignedBy: Users?.assignee ? Users?.assignee?.firstName + " " + Users.assignee?.lastName : '-',
                                duration: `${startTimeString} - ${endTimeString}`,
                                status: Users?.status,
                                tabKey: state?.tabKey,
                            });
                        }

                        else {

                            if (appliedUsers?.length > 0 && Users?.status == 'applied' && acceptedUsers?.length < noOfAssigness) {
                                resources?.push({
                                    id: appliedUsers[0]?.id,
                                    name: "Requests " + appliedUsers?.length + '/' + noOfAssigness,
                                    image: avatar
                                });

                                events.push({
                                    id: item?.id,
                                    break: item?.break,
                                    howMany: item?.howMany,
                                    start: startTimeMoment,
                                    end: endTimeMoment,
                                    property: item?.property ? item?.property?.name : '',
                                    resourceId: appliedUsers[0]?.id,
                                    title: startTimeString + " - " + endTimeString,
                                    bgColor: "#EEB600",
                                    location: item?.address ? `${item.address?.streetNumber},${item.address?.streetName},${item.address?.city},${item.address?.buildingNumber}` : '',
                                    Eligibility: item?.eligibilitity ? item?.eligibilitity?.name : '',
                                    Schedules: '-',
                                    assignedBy: appliedUsers[0]?.assignee ? appliedUsers[0]?.assignee?.firstName + " " + appliedUsers[0]?.assignee?.lastName : '-',
                                    duration: `${startTimeString} - ${endTimeString}`,
                                    status: appliedUsers[0]?.status,
                                    tabKey: state?.tabKey,
                                    completeRecord: item,
                                    shiftDetailHandler
                                });

                                appliedUsers = []
                            }
                            else if (Users?.status !== 'applied') {
                                resources?.push({
                                    id: Users?.id,
                                    name: Users?.user?.firstName + " " + Users?.user?.lastName,
                                    image: Users?.profileImg ? Users?.profileImg[0]?.url : avatar
                                });

                                events.push({
                                    id: item?.id,
                                    break: item?.break,
                                    howMany: item?.howMany,
                                    start: startTimeMoment,
                                    end: endTimeMoment,
                                    property: item?.property ? item?.property?.name : '',
                                    resourceId: Users?.id,
                                    title: startTimeString + " - " + endTimeString,
                                    bgColor: "#428A42",
                                    location: item?.address ? `${item.address?.streetNumber},${item.address?.streetName},${item.address?.city},${item.address?.buildingNumber}` : '',
                                    Eligibility: item?.eligibilitity ? item?.eligibilitity?.name : '',
                                    Schedules: Users?.user?.schedules[0]?.name + '...',
                                    assignedBy: Users?.assignee ? Users?.assignee?.firstName + " " + Users.assignee?.lastName : '-',
                                    duration: `${startTimeString} - ${endTimeString}`,
                                    status: Users?.status,
                                    tabKey: state?.tabKey,
                                    completeRecord: item,
                                });
                            }

                        }
                    }
                }

                else if (state?.tabKey !== 0) {

                    resources?.push({
                        id: item?.id,
                        name: "Requests " + 0 + '/' + noOfAssigness,
                        image: avatar
                    });

                    events.push({
                        id: item?.id,
                        break: item?.break,
                        howMany: item?.howMany,
                        start: startTimeMoment,
                        end: endTimeMoment,
                        property: item?.property ? item?.property?.name : '',
                        resourceId: item?.id,
                        title: startTimeString + " - " + endTimeString,
                        bgColor: "#EEB600",
                        location: item?.address ? `${item.address?.streetNumber},${item.address?.streetName},${item.address?.city},${item.address?.buildingNumber}` : '',
                        Eligibility: item?.eligibilitity ? item?.eligibilitity?.name : '',
                        Schedules: '-',
                        assignedBy: '-',
                        duration: `${startTimeString} - ${endTimeString}`,
                        status: 'not applied',
                        tabKey: state?.tabKey,
                        completeRecord: item,
                        shiftDetailHandler
                    });

                    appliedUsers = []
                }
            }

            setResources(resources)
            setEvents(events)
        }
    }, [shiftRecords, state?.tabKey])


    const { data: eligibilityOptions } = useQuery('eligiblity', getEligibiity, { enabled: drawerStates.filter });
    const { data: scheduleOptions } = useQuery('schedule', getSchedules, { enabled: drawerStates.filter });
    const { data: usersOptions } = useQuery('users', getAllUsers, { enabled: drawerStates.filter });


    // to select a shift from open or normal
    const assignShiftHandler = () => {
        setDrawerStates({ ...drawerStates, assignShiftDrawer: true })
    }

    // type of drawer open on the basis of type selection for shift
    const shiftTypeHandler = (type) => {
        if (type == 'open') {
            setDrawerStates((prev) => ({ ...prev, assignShiftDrawer: false, shiftTypeDrawer: true, typeOfShift: type }))
            return
        }
        else {
            setDrawerStates((prev) => ({ ...prev, assignShiftDrawer: false, shiftTypeDrawer: true, typeOfShift: type }))
            return
        }
    }

    const paginationHandler = (page, limit) => {
        const { schedule, user, eligiblity } = state?.filterDrawer;
        const { tabKey } = state;
        setState((prev) => ({ ...prev, page: page, limit: 5, filterDrawer: { schedule: schedule, user: user, eligiblity: eligiblity } }));
        setResources([])
        setEvents([])
        dispatch(ShiftSchedulerAction.GetShifts({ page, limit, tabKey, filterDrawer: { schedule: schedule, user: user, eligiblity: eligiblity } }));

    }

    // filter search handler
    const incidentFilters = () => {
        const { schedule, user, eligiblity } = state.filterDrawer;

        let values = Object.values(state.filterDrawer).some(item => item !== '');
        if (!values) {
            return
        }

        else {
            setState((prev) => ({ ...prev, page: 1, filterDrawer: { schedule: schedule, user: user, eligiblity: eligiblity } }));
            let payload = {
                limit: state?.limit,
                page: 1,
                tabKey: state?.tabKey,
                filterDrawer: {
                    schedule: schedule ? schedule : "",
                    user: user ? user : "",
                    eligiblity: eligiblity ? eligiblity : ""
                }
            }
            setResources([])
            setEvents([])
            dispatch(ShiftSchedulerAction.GetShifts(payload))
            setDrawerStates({ ...drawerStates, filter: false })
        }
    }

    // Filter states cleaner
    const clearFilters = () => {
        if (drawerStates.filter) {
            setState((prev) => ({
                ...prev,
                limit: state?.limit,
                page: 1,
                filterDrawer: {
                    filterDrawer: {
                        schedule: '',
                        user: '',
                        eligiblity: ''
                    }
                }
            }))
            let payload = {
                limit: state?.limit,
                page: 1,
                tabKey: state?.tabKey,
                filterDrawer: {
                    schedule: '',
                    user: '',
                    eligiblity: ''
                }
            }
            setResources([])
            setEvents([])
            dispatch(ShiftSchedulerAction.GetShifts(payload))
            setDrawerStates({ ...drawerStates, filter: false })
        }
        return
    }

    const tabChangeHandler = (activeKey) => {
        setState({ ...state, page: 1, tabKey: activeKey, filterDrawer: { ...state.filterDrawer } })
        dispatch(ShiftSchedulerAction.GetShifts({ ...state, tabKey: activeKey, page: 1 }))
    }

    return (
        <>
            <Row gutter={[8, 16]} align={'middle'} justify={'space-between'} className={`w-full py-4`}>
                <Col
                    lg={userRole == "admin" || userRole == "manager" ? 24 : 10}
                    xl={userRole == "admin" || userRole == "manager" ? 12 : 10}
                    md={10} sm={10} xs={24}>
                    <h1 className='text-xl font-semibold'>Workforce Management</h1>
                </Col >
                {
                    userRole == "admin" || userRole == "manager" ?
                        <Col lg={8} xl={5} md={7} sm={12} xs={24} >
                            <Button.Basic
                                className="text-white outline-none border-none w-full cursor-pointer px-2"
                                style={{ height: "40px", background: Colors.Yellow, color: Colors.White }}
                                text='Create Shift'
                                icon={<PlusOutlined />}
                                onClick={() => assignShiftHandler()}
                            />
                        </Col>
                        : ""
                }
            </Row >

            <Row className='flex items-center gap-2 my-5'>
                <IoArrowBack className='cursor-pointer' size={18} onClick={() => navigate('/workforce-management')} />
                Back
            </Row>

            <BasicDrawer
                openDrawer={drawerStates?.assignShiftDrawer}
                width={400}
                title={'Select the type of Shift'}
                onClose={() => setDrawerStates((prev) => ({ ...prev, assignShiftDrawer: false }))}
                children={
                    <Row className='w-full mt-4 flex justify-around gap-3 flex-wrap' gutter={[16, 32]}>
                        <Col span={10}
                            onClick={() => shiftTypeHandler('open')}
                            style={{ background: Colors.Yellow, color: 'white' }} className='flex items-center justify-center rounded-md p-4 cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                            <div>
                                <BasicText text={'Open Shift'} className='text-lg '
                                />
                            </div>
                        </Col>

                        <Col span={10}
                            onClick={() => shiftTypeHandler('normal')}
                            style={{ background: Colors.Yellow, color: 'white' }} className='flex items-center justify-center gap-3 p-4 rounded-md cursor-pointer hover:scale-x-105 hover:scale-y-105 duration-500 ease-in-out delay-75'>
                            <div>
                                <BasicText text={'Normal Shift'} className='text-lg '
                                />
                            </div>
                        </Col>
                    </Row>
                }
            />

            <AssignShiftDrawer
                drawerStates={drawerStates}
                setDrawerStates={setDrawerStates}
                shiftState={state}
                setShiftState={setState}
            />

            {/* scheduler component */}
            <Row className='w-full py-5 px-2' style={{ width: '100%', background: 'white' }}>

                <Col span={24} className='px-2 flex items-center justify-between flex-wrap'>
                    <BasicText text={'Shift Scheduler'} className='text-xl font-semibold' />
                    <img src={filter} height={35} width={35} className='cursor-pointer' onClick={() => setDrawerStates({ ...drawerStates, filter: true })} />
                </Col>

                <Col span={24} className='mt-4'>
                    <Tabs
                        type='card'
                        onChange={tabChangeHandler}
                        defaultActiveKey={state?.tabKey}
                        items={
                            [
                                {
                                    label: `Normal Shifts`,
                                    key: 0,
                                    children: ``,
                                },
                                {
                                    label: `Open Shifts`,
                                    key: 1,
                                    children: ``,
                                }
                            ]
                        }
                    />
                </Col>

                {
                    loader ?
                        <Circular
                            spinning={loader}
                            size={40}
                            color={Colors.Yellow}
                            containerStyle={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        />
                        :
                        <>
                            <App events={events} resources={resources} />
                            <Col span={24} className='flex items-center justify-end'>
                                <Pagination.Basic
                                    total={paginate?.total}
                                    pageSize={state?.limit}
                                    current={state?.page}
                                    onChange={paginationHandler}
                                    showSizeChanger={''}
                                    containerStyle={{ marginRight: '24px' }}
                                />
                            </Col>
                        </>
                }
            </Row>

            {/* Filter drawer */}
            <BasicDrawer
                openDrawer={drawerStates?.filter}
                onClose={() => setDrawerStates({ ...drawerStates, filter: false })}
                width={300}
                title={'Filter'}
                children={
                    <>
                        <Row gutter={[16, 32]} className='flex w-full flex-col h-full justify-between'>
                            <Row gutter={[1, 12]} className='w-full'>
                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Schedule</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            value={state?.filterDrawer?.schedule}
                                            onChange={(value) => {
                                                if (value !== undefined && value !== null) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            schedule: value
                                                        }
                                                    }));
                                                } else {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            schedule: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                            options={scheduleOptions ? scheduleOptions : []}
                                        />
                                    </ConfigProvider>
                                </Col>

                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>User</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            value={state?.filterDrawer?.user}
                                            onChange={(value) => {
                                                if (value !== undefined && value !== null) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            user: value
                                                        }
                                                    }));
                                                } else {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            user: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                            options={usersOptions ? usersOptions : []}
                                        />
                                    </ConfigProvider>
                                </Col>


                                <Col span={24}>
                                    <label className='font-normal' style={{ color: Colors.LabelColor }}>Position</label>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    optionSelectedBg: Colors.Yellow,
                                                    optionActiveBg: Colors.LightGray,
                                                    optionSelectedColor: Colors.White
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            allowClear={true}
                                            className='w-full h-9 outline-none rounded'
                                            value={state?.filterDrawer?.eligiblity}
                                            onChange={(value) => {
                                                if (value !== undefined && value !== null) {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            eligiblity: value
                                                        }
                                                    }));
                                                } else {
                                                    setState(prev => ({
                                                        ...prev,
                                                        filterDrawer: {
                                                            ...prev.filterDrawer,
                                                            eligiblity: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                            options={eligibilityOptions ? eligibilityOptions : []}

                                        />
                                    </ConfigProvider>
                                </Col>
                            </Row>

                            {/* <Col span={24}>
                                <label className='font-normal' style={{ color: Colors.LabelColor }}>View Options</label>
                                <div className='w-full'>
                                    <div>
                                        <input type='checkbox' value={''} onChange={setState((prev) => ({...prev, filterDrawer:{...prev.filterDrawer, confirmedShifts:''}}))} />
                                        <label>Confirmed Shifts</label>
                                    </div>
                                    <div>
                                        <input type='checkbox' value={''} />
                                        <label>Un-Confirmed Shifts</label>
                                    </div>
                                </div>
                            </Col> */}

                            <Row className='w-full flex justify-between gap-4 px-2 self-end' align={'middle'}>
                                <Col span={11}>
                                    <Button.Basic onClick={incidentFilters} style={{ color: 'white', backgroundColor: Colors.Yellow }} text={'Search'} className={'w-full rounded'} size={'large'} />
                                </Col>
                                <Col span={11}>
                                    <Button.Basic onClick={clearFilters} style={{ color: 'white', backgroundColor: Colors.Error }} text={'Clear'} className={'w-full rounded'} size={'large'} />
                                </Col>
                            </Row>
                        </Row>
                    </>
                }
            />

            {/* Detail shift drawer only for the open shifts it will open in case of status is Applied  */}
            <DetailShiftDrawer
                drawerStates={drawerStates}
                setDrawerStates={setDrawerStates}
                openShiftDetailData={openShiftDetail}
                state={state}
                setState={setState}
            />

        </>

    )
}

export default ShiftScheduler




// {
//     "id": 1,
//     "status": "pending",
//     "user": {
//         "id": 1,
//         "userName": "Therong1999",
//         "email": "davidsgarrison@jourrapide.com",
//         "firstName": "David",
//         "lastName": "S Garrison",
//         "phoneNo": "626-488-1408",
//         "status": "active",
//         "lastLogin": null,
//         "profileImg": [],
//         "userRole": [
//             {
//                 "id": 4,
//                 "name": "guard",
//                 "createdAt": "2024-04-22T12:20:59.000000Z",
//                 "updatedAt": "2024-04-22T12:20:59.000000Z"
//             }
//         ],
//         "eligibilities": [
//             {
//                 "id": 1,
//                 "name": "Security Incharge",
//                 "createdAt": "2024-04-22T14:03:46.000000Z",
//                 "updatedAt": "2024-04-22T14:03:46.000000Z"
//             }
//         ],
//         "schedules": [],
//         "certificateInfo": null
//     },
//     "assignee": {
//         "id": 9,
//         "userName": "auser1",
//         "email": "naveed.siddiqui@code-avenue.com",
//         "firstName": "admin",
//         "lastName": "user",
//         "phoneNo": "(123) 123-7225",
//         "status": "active",
//         "lastLogin": null,
//         "profileImg": [],
//         "userRole": [
//             {
//                 "id": 1,
//                 "name": "admin",
//                 "createdAt": "2024-04-22T12:20:59.000000Z",
//                 "updatedAt": "2024-04-22T12:20:59.000000Z"
//             }
//         ],
//         "eligibilities": [
//             {
//                 "id": 1,
//                 "name": "Security Incharge",
//                 "createdAt": "2024-04-22T14:03:46.000000Z",
//                 "updatedAt": "2024-04-22T14:03:46.000000Z"
//             }
//         ],
//         "schedules": [],
//         "certificateInfo": null
//     }
// },

// "startTime": "02:59:00",
// "endTime": "08:30:00",
// "shiftDate": null,
// "startDate": "2024-05-06",
// "endDate": "2024-06-06",

// const events = [
//     {
//         id: 1,
//         start: customStartTime.format("YYYY-MM-DD HH:mm:ss"),
//         end: customEndTime.format("YYYY-MM-DD HH:mm:ss"),
//         resourceId: "r0",
//         title: "Test",
//         bgColor: "#EEB600",
//         propertyName: "Testing property",
//         location: "Karachi jauhar block 14",
//         Eligibility: "Armed Guard",
//         Schedules: "East Buffalo",
//         AssignedBy: "Martin",
//     },

// id: 1,
// start: customStartTime.format("YYYY-MM-DD HH:mm:ss"),
// end: customEndTime.format("YYYY-MM-DD HH:mm:ss"),
// resourceId: "r0",
// title: "Test",
// bgColor: "#EEB600",
// propertyName: "Testing property",
// location: "Karachi jauhar block 14",
// Eligibility: "Armed Guard",
// Schedules: "East Buffalo",
// AssignedBy: "Martin",

// console.log("Resoucrs,evengts", Events)


// console.log("array id", arr[0]?.id)

// //   {
//     id: 1,
//     start: customStartTime.format("YYYY-MM-DD HH:mm:ss"),
//     end: customEndTime.format("YYYY-MM-DD HH:mm:ss"),
//     resourceId: "r0",
//     title: "Test",
//     bgColor: "#EEB600",
//     propertyName: "Testing property",
//     location: "Karachi jauhar block 14",
//     Eligibility: "Armed Guard",
//     Schedules: "East Buffalo",
//     AssignedBy: "Martin",
// },