import { CLEAR_ATTENDENCE, CLEAR_ATTENDENCE_FILTER, GET_ATTENDENCE, GET_ATTENDENCE_FAILURE, GET_ATTENDENCE_PROPERTIES, GET_ATTENDENCE_PROPERTIES_FAILURE, GET_ATTENDENCE_PROPERTIES_SUCCESS, GET_ATTENDENCE_SCHEDULES, GET_ATTENDENCE_SCHEDULES_FAILURE, GET_ATTENDENCE_SCHEDULES_SUCCESS, GET_ATTENDENCE_SUCCESS, GET_ATTENDENCE_USERS, GET_ATTENDENCE_USERS_FAILURE, GET_ATTENDENCE_USERS_SUCCESS } from "../constants";

export default class AttendenceManagementAction {
    static GetAttendence(payload) {
        return {
            type: GET_ATTENDENCE,
            payload
        }
    }
    static GetAttendenceSuccess(payload) {
        return {
            type: GET_ATTENDENCE_SUCCESS,
            payload
        }
    }
    static GetAttendenceFailure() {
        return {
            type: GET_ATTENDENCE_FAILURE
        }
    }
    static GetAttendenceProperties(payload, cb) {
        return {
            type: GET_ATTENDENCE_PROPERTIES,
            payload,
            cb
        }
    }
    static GetAttendencePropertiesSuccess(payload) {
        return {
            type: GET_ATTENDENCE_PROPERTIES_SUCCESS,
            payload
        }
    }
    static GetAttendencePropertiesFailure() {
        return {
            type: GET_ATTENDENCE_PROPERTIES_FAILURE
        }
    }
    static GetAttendenceSchedules(payload) {
        return {
            type: GET_ATTENDENCE_SCHEDULES,
            payload
        }
    }
    static GetAttendenceSchedulesSuccess(payload) {
        return {
            type: GET_ATTENDENCE_SCHEDULES_SUCCESS,
            payload
        }
    }
    static GetAttendenceSchedulesFailure() {
        return {
            type: GET_ATTENDENCE_SCHEDULES_FAILURE
        }
    }
    static GetAttendenceUsers(payload) {
        return {
            type: GET_ATTENDENCE_USERS,
            payload
        }
    }
    static GetAttendenceUsersSuccess(payload) {
        return {
            type: GET_ATTENDENCE_USERS_SUCCESS,
            payload
        }
    }
    static GetAttendenceUsersFailure() {
        return {
            type: GET_ATTENDENCE_USERS_FAILURE
        }
    }
    static ClearAttendenceFilter() {
        return {
            type: CLEAR_ATTENDENCE_FILTER,
        }
    }
    static ClearAttendence(){
        return{
            type: CLEAR_ATTENDENCE
        }
    }
}