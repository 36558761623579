export default function DateTimeComponent(datetimeStr, type) {

    // Parse the datetime string
    const parsedDatetime = new Date(datetimeStr);

    // Extract year, month, and date components
    const year = parsedDatetime.getFullYear();
    const month = String(parsedDatetime.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const date = String(parsedDatetime.getDate()).padStart(2, '0');

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${date}`;

    // Extract time components
    const hours = String(parsedDatetime.getHours()).padStart(2, '0');
    const minutes = String(parsedDatetime.getMinutes()).padStart(2, '0');
    const seconds = String(parsedDatetime.getSeconds()).padStart(2, '0');

    // Format the time as HH:MM
    const formattedTime = `${hours}:${minutes}`;
    
    // Format the complete date and time as YYYY-MM-DD HH:MM:SS
    const completeDateTime = `${formattedDate} ${hours}:${minutes}:${seconds}`;

    if (type === 'date') {
        return formattedDate;
    } else if (type === 'time') {
        return formattedTime;
    } else {
        return completeDateTime;
    }
}
