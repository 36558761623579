import React from 'react'
import { Row, Col, Modal, Button } from 'antd'
import { Colors } from '../../config';
import giphy from '../../assets/images/giphy.gif'
import { Link } from 'react-router-dom';

const CommingSoon = () => {
  return (
    <Row className='min-h-screen w-full mx-auto my-2 py-2 flex items-center justify-center'>
      <Col span={14} className='h-fit relative p-2 flex'>
        <div className='absolute h-52 w-52 rounded-full left-[-6px] top-[-6px] z-0 hidden lg:flex' style={{border:'7px solid #F36A68'}}>
        </div>
        <div className='w-fit py-2 px-4 lg:rounded-tl-full lg:rounded-bl-full flex items-center justify-center lg:justify-start flex-wrap gap-4 z-10' style={{ border: '3px solid #EEB600', background:Colors.Yellow }}>
          <div className='rounded-full h-32 block w-32' style={{ background: '#E8E8E8', border: '1px solid #EEB600' }}>
            <img src={giphy} />
          </div>
          <div className='flex flex-col items-center lg:items-start gap-2 w-80 p-2 py-4'>
            <h3 className='font-semibold text-xl' style={{ color: 'white' }}>Coming Soon !</h3>
            <p style={{color:'white'}}>Thank you for visiting our website. We're working hard to bring you something amazing. Stay tuned for updates!</p>
            <Link to={'/'}>
              <Button style={{ background: '#F36A68', color: 'white' }} className='outline-none border-none'>Back To Home</Button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default CommingSoon