import { ApiCaller, Utils } from "../../config";
import { put } from "redux-saga/effects";
import { AttendenceManagementAction } from "../actions";

export default class AttendenceManagementMiddleware {
    static *GetAttendenceMiddleware(params) {
        const { payload } = params
        let state = payload?.state || payload?.filterStates;
        const { startingDate, endingDate, property, schedule, user } = state?.filterDrawer;
        try {
            let res = yield ApiCaller.Get(`/attendance?userId=${user}&startDate=${startingDate}&endDate=${endingDate}&propertyId=${property}&scheduleId=${schedule}`);
            yield put(AttendenceManagementAction.GetAttendenceSuccess(res?.data?.data))
        } catch (error) {
            yield put(AttendenceManagementAction.GetAttendenceFailure());
            Utils.showMessage('error', err?.response?.data?.message);
        }
    }

    static *GetAttendencePropertiesMiddleware(params) {
        const { payload } = params;
        try {
            let res = yield ApiCaller.Get(`/shifts/properties?startDate=${payload.startDate}&endDate=${payload.endDate}`);
            let propertiesOptions = res?.data?.data;
            let propertiesData = propertiesOptions.map((prop) => {
                return {
                    label: prop.name,
                    value: prop.id
                }
            })
            yield put(AttendenceManagementAction.GetAttendencePropertiesSuccess(propertiesData))
        } catch (error) {
            yield put(AttendenceManagementAction.GetAttendencePropertiesFailure())
            Utils.showMessage('error', error?.response?.data?.message)
        }
    }

    static *GetAttendenceSchedulesMiddleware(params) {
        const { payload } = params;
        const id = Number(payload)
        try {
            let res = yield ApiCaller.Get(`/property/${id}`);
            let schedulesOptions = res?.data?.data?.schedules;
            let scheduleData = schedulesOptions.map((prop) => {
                return {
                    label: prop.name,
                    value: prop.id
                }
            })
            yield put(AttendenceManagementAction.GetAttendenceSchedulesSuccess(scheduleData))
        } catch (error) {
            yield put(AttendenceManagementAction.GetAttendenceSchedulesFailure())
            Utils.showMessage('error', error?.response?.data?.message)
        }

    }

    static *GetAttendenceUsersMiddleware(params) {
        const { payload } = params
        const { startingDate, endingDate, property } = payload?.state?.filterDrawer;
        const scheduleId = Number(payload?.value);
        try {
            let res = yield ApiCaller.Get(`/shifts/users?startDate=${startingDate}&endDate=${endingDate}&propertyId=${property}&scheduleId=${scheduleId}`);
            let usersOptions = res?.data?.data;
            console.log(usersOptions)
            let usersData = usersOptions.map((prop) => {
                return {
                    label: prop.userName,
                    value: prop.id
                }
            })
            yield put(AttendenceManagementAction.GetAttendenceUsersSuccess(usersData))
        } catch (error) {
            yield put(AttendenceManagementAction.GetAttendenceUsersFailure())
            Utils.showMessage('error', error?.response?.data?.message)
        }
    }
}