import { CLEAR_ATTENDENCE, CLEAR_ATTENDENCE_FILTER, GET_ATTENDENCE, GET_ATTENDENCE_FAILURE, GET_ATTENDENCE_PROPERTIES, GET_ATTENDENCE_PROPERTIES_FAILURE, GET_ATTENDENCE_PROPERTIES_SUCCESS, GET_ATTENDENCE_SCHEDULES, GET_ATTENDENCE_SCHEDULES_FAILURE, GET_ATTENDENCE_SCHEDULES_SUCCESS, GET_ATTENDENCE_SUCCESS, GET_ATTENDENCE_USERS, GET_ATTENDENCE_USERS_FAILURE, GET_ATTENDENCE_USERS_SUCCESS } from "../constants";

const initialState = {
    loader: false,
    attendenceData: [],
    propertiesData: [],
    usersData: [],
    schedulesData: []
}

export default function AttendenceManagementReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ATTENDENCE:
            state = {
                ...state,
                loader: true,
            }
            break;

        case GET_ATTENDENCE_SUCCESS:
            state = {
                ...state,
                loader: false,
                attendenceData: action.payload
            }
            break;

        case GET_ATTENDENCE_FAILURE:
            state = {
                ...state,
                loader: false
            }
            break;

        case GET_ATTENDENCE_PROPERTIES:
            state = {
                ...state,
            }
            break;

        case GET_ATTENDENCE_PROPERTIES_SUCCESS:
            state = {
                ...state,
                propertiesData: action.payload,
                usersData: [],
                schedulesData: []
            }
            break;

        case GET_ATTENDENCE_PROPERTIES_FAILURE:
            state = {
                ...state,
            }
            break;
        case GET_ATTENDENCE_SCHEDULES:
            state = {
                ...state,
            }
            break;
        case GET_ATTENDENCE_SCHEDULES_SUCCESS:
            state = {
                ...state,
                schedulesData: action.payload
            }
            break;
        case GET_ATTENDENCE_SCHEDULES_FAILURE:
            state = {
                ...state,
            }
            break;
        case GET_ATTENDENCE_USERS:
            state = {
                ...state,
            }
            break;
        case GET_ATTENDENCE_USERS_SUCCESS:
            state = {
                ...state,
                usersData: action.payload
            }
            break;
        case GET_ATTENDENCE_USERS_FAILURE:
            state = {
                ...state,
            }
            break;
        case CLEAR_ATTENDENCE_FILTER:
            state = {
                ...state,
                propertiesData: [],
                usersData: [],
                schedulesData: []
            }
            break;
        case CLEAR_ATTENDENCE:
            state = {
                ...state,
                propertiesData: [],
                usersData: [],
                schedulesData: [],
                attendenceData: []
            }
            break;

        default:
            break;
    }
    return state
}