import { GET_TOR, GET_TOR_DETAIL, GET_TOR_DETAIL_FAILURE, GET_TOR_DETAIL_SUCCESS, GET_TOR_FAILURE, GET_TOR_SUCCESS } from "../constants";

export default class ShiftTorAction {
    static GetTors(payload) {
        return {
            type: GET_TOR,
            payload
        }
    }

    static GetTorsSuccess(payload) {
        return {
            type: GET_TOR_SUCCESS,
            payload
        }
    }

    static GetTorsFailure() {
        return {
            type: GET_TOR_FAILURE
        }
    }

    static GetTorDetail(payload, cb) {
        return {
            type: GET_TOR_DETAIL,
            payload,
            cb
        }
    }

    static GetTorDetailSuccess(payload) {
        return {
            type: GET_TOR_DETAIL_SUCCESS,
            payload
        }
    }

    static GetTorDetailFailure() {
        return {
            type: GET_TOR_DETAIL_FAILURE,
        }
    }
}